import axios from 'axios'

export const genericApi = {
    getCitiesByGeoLocations
};

async function callGetMethod(url, requestDataBlk, successCallBack, errorCallBack){
    try {
        await axios.get(url, requestDataBlk).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
        })
    } catch (error) {
        alert(error);
        if(errorCallBack){ errorCallBack({...error}) }
    }
}

async function getCitiesByGeoLocations(query, successCallBack, errorCallBack) {
    const url = `api/v2/generic/get-locations?q=${query}`;
    await callGetMethod (url, null, successCallBack, errorCallBack);
}
