import axios from 'axios';

async function callGetMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .get(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .get(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}
async function callPutMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .put(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .put(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}
async function callPostMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .post(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .post(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}
async function callDeleteMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .delete(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .delete(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}

async function getAllGivePreferenceList(successCallBack, errorCallBack) {
  await callGetMethod(
    'api/core/master/give-preferences',
    null,
    successCallBack,
    errorCallBack
  );
}

async function getUserGivePreference(successCallBack, errorCallBack) {
  await callGetMethod(
    'api/core/user/account/preferences/give',
    null,
    successCallBack,
    errorCallBack
  );
}

async function getItemsFieldsTrue(
  categorySlug,
  successCallBack,
  errorCallBack
) {
  const url =
    '' +
    'api/core/master/get-item-fields/' +
    categorySlug +
    '?is_filterable=true';
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getFieldsByCategory(
  categorySlug,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url = '' + 'api/core/master/get-item-fields/' + categorySlug;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
async function getDependencyChoices(
  categoryId,
  selectionId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    'api/core/master/get-dependency-choices/' +
    categoryId +
    '/' +
    selectionId;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
async function getBooksListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMusicListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMagazineListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod('', null, successCallBack, errorCallBack);
}

async function getBookDetails(
  categorySlug,
  fieldId,
  uniqueId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' + `api/core/master/get-product-details/${categorySlug}/${uniqueId}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getItemDetails(
  categorySlug,
  fieldId,
  uniqueId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-product-details/${categorySlug}?unique_id=${uniqueId}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getShoeListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMoviesListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  //   const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getClothsListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getGadgetsListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  //   const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getGamesListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getToysListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function createBundle(successCallBack, errorCallBack) {
  await callPostMethod(
    'api/v2/core/bundle',
    {},
    successCallBack,
    errorCallBack
  );
}
async function createItem(requestBlk, successCallBack, errorCallBack) {
  await callPostMethod(
    'api/v2/core/item',
    requestBlk,
    successCallBack,
    errorCallBack
  );
}

async function submitBundle(requestBlk, successCallBack, errorCallBack) {
  await callPutMethod(
    'api/core/bundle/submit',
    requestBlk,
    successCallBack,
    errorCallBack
  );
}

async function getItemsByBundle(bundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    '' + `api/v2/core/bundle/${bundleId}`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function deleteItemFromBundle(
  bundleId,
  ItemId,
  successCallBack,
  errorCallBack
) {
  await callDeleteMethod(
    '' + `api/v2/core/item/${bundleId}/${ItemId}`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getMyGivesList(
  filter,
  sortValue,
  paginationKey,
  successCallBack,
  errorCallBack
) {
  let url = `api/v2/core/mygives?filter=${encodeURIComponent(
    JSON.stringify(filter)
  )}&sorting=${sortValue}`;
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMyGiveItemByBundle(bundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    `api/v2/core/mygives?filter=${encodeURIComponent(
      JSON.stringify({ bundle_id: bundleId })
    )}`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getMyGetItemByBundle(bundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    `api/v2/core/mygets/${bundleId}`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getMyGetsList(
  requestDataType,
  filter,
  sortValue,
  paginationKey,
  successCallBack,
  errorCallBack
) {
  let url = `api/v2/core/request?r_status=${requestDataType}&filter=${filter}&sorting=${sortValue}`;
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getItemsList(
  filter,
  sortValue,
  paginationKey,
  cityFilterData,
  successCallBack,
  errorCallBack
) {
  let url = `api/core/bundle/search?filter=${filter}&sorting=${sortValue}`;
  if (cityFilterData) {
    url = url + '&data=' + cityFilterData;
  }
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getItemsDetails(
  requestDataType,
  successCallBack,
  errorCallBack
) {
  await callGetMethod(
    'api/v2/core/bundle/' + requestDataType,
    null,
    successCallBack,
    errorCallBack
  );
}
async function getItem(category, bundleId, ItemId, successCallBack, errorCallBack) {
  await callGetMethod(
    `api/v2/core/item/${category}/${bundleId}/${ItemId}`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function updateItem(
  requestBlk,
  bundleId,
  ItemId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT,true , requestBlk)
  const url = '' + `api/v2/core/item/${bundleId}/${ItemId}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack)
  await callPutMethod(url, requestBlk, successCallBack, errorCallBack);
}

async function putRequestItem(requestDataBlk, successCallBack, errorCallBack) {
  await callPostMethod(
    'api/v2/core/request',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}
async function getRequestsByBundleId(BundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    'api/v2/core/request-by-bundle/' + BundleId,
    null,
    successCallBack,
    errorCallBack
  );
}

async function updateRequest(requestDataBlk, successCallBack, errorCallBack) {
  await callPutMethod(
    'api/v2/core/request',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}
async function confirmRequest(requestDataBlk, successCallBack, errorCallBack) {
  await callPutMethod(
    'api/v2/core/request',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}

async function getItemExtraInfo(category, searchId, successCallBack, errorCallBack) {
  await callGetMethod(
    `api/v2/core/category/${category}/search/${searchId}`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function updateBundleStatus(
  bundleId,
  type,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
  const url = `api/core/bundle/${bundleId}?action=${type}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callPutMethod(url, requestDataBlk, successCallBack, errorCallBack);
}

async function submitFeedback(
  bundleId,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
  const url = `api/core/bundle/${bundleId}/rate`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callPutMethod(url, requestDataBlk, successCallBack, errorCallBack);
}

async function updateUserGivePreference(
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  await callPutMethod(
    'api/core/user/account/preferences/give',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}
async function setBundleConfirmed(
  requestDataBlock,
  successCallBack,
  errorCallBack
) {
  await callPostMethod(
    'api/core/bundle/confirmed',
    requestDataBlock,
    successCallBack,
    errorCallBack
  );
}
async function updateBundleRejectRequest(
  bundle_id,
  request_id,
  successCallBack,
  errorCallBack
) {
  await callPutMethod(
    `api/v2/core/bundle/${bundle_id}`,
    {
      request_id: request_id,
      b_status: 'rejected'
    },
    successCallBack,
    errorCallBack
  );
}
async function getItemFlagTypes(successCallBack, errorCallBack) {
  await callGetMethod(
    'api/v2/core/flags',
    null,
    successCallBack,
    errorCallBack
  );
}
async function flagItem(requestDataBlock, successCallBack, errorCallBack) {
  await callPostMethod(
    'api/v2/core/bundle-flag',
    requestDataBlock,
    successCallBack,
    errorCallBack
  );
}
async function getNotifications(
  notificationFilter,
  successCallBack,
  errorCallBack
) {
  // async function getNotifications(filter, sortValue, paginationKey, cityFilterData, successCallBack, errorCallBack) {
  // if (paginationKey) {
  //     let keyString = encodeURIComponent(JSON.stringify(paginationKey.key))
  //     url = url + '&page=' + keyString
  // }

  let url = 'api/notifications';

  let urlParam;
  if (Object.keys(notificationFilter).length > 0) {
    if (notificationFilter.read) {
      urlParam = 'read=True';
    } else {
      urlParam = 'read=False';
    }

    url = `${url}?${urlParam}`;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
async function updateNotificationById(
  notificationId,
  action,
  successCallBack,
  errorCallBack
) {
  await callPutMethod(
    `api/notifications/update/${action}/${notificationId}`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function updateNotifications(action, successCallBack, errorCallBack) {
  await callPutMethod(
    `api/notifications/update/${action}`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getNotificationCount(
  notificationFilter,
  successCallBack,
  errorCallBack
) {
  let url = 'api/notifications/count';
  let urlParam;
  if (Object.keys(notificationFilter).length > 0) {
    if (notificationFilter.read) {
      urlParam = 'read=True';
    } else {
      urlParam = 'read=False';
    }

    url = `${url}?${urlParam}`;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}


async function getItemCategory(successCallBack, errorCallBack) {
  let url = 'api/v2/core/category'
  await callGetMethod(url, null, successCallBack, errorCallBack)
}

async function getItemFieldSearchOption(category, query, successCallBack, errorCallBack) {
  let url = `api/v2/core/category/${category}/search?search_keyword=${query}`
  await callGetMethod(url, null, successCallBack, errorCallBack)
}

async function getSelectedItemSearch(category, uniqueId, successCallBack, errorCallBack) {
  let url = `api/v2/core/category/${category}/search/${uniqueId}`
  await callGetMethod(url, null, successCallBack, errorCallBack)
}

async function createBundleV2(data, successCallBack, errorCallBack) {
  let url = 'api/v2/core/bundle'
  await callPostMethod(url, data, successCallBack, errorCallBack)
}

async function createItemV2(data, successCallBack, errorCallBack) {
  let url = 'api/v2/core/item'
  await callPostMethod(url, data, successCallBack, errorCallBack)
}


async function getMyGivesListV2(
  filter,
  sortValue,
  paginationKey,
  successCallBack,
  errorCallBack
) {
  let url = `/api/v2/core/mygives?filter=${encodeURIComponent(JSON.stringify(filter))}&sorting=${sortValue}`;
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
export const itemApi = {
  getAllGivePreferenceList,
  getUserGivePreference,
  updateUserGivePreference,
  getFieldsByCategory,
  getDependencyChoices,
  getBooksListByQuery,
  getMusicListByQuery,
  getMagazineListByQuery,
  getClothsListByQuery,
  getBookDetails,
  getShoeListByQuery,
  getGadgetsListByQuery,
  getMoviesListByQuery,
  getGamesListByQuery,
  getToysListByQuery,
  createBundle,
  createItem,
  submitBundle,
  getItemsByBundle,
  deleteItemFromBundle,
  getItemDetails,
  getMyGivesList,
  getMyGetsList,
  getItemsList,
  getItemsDetails,
  getItem,
  updateItem,
  putRequestItem,
  getRequestsByBundleId,
  updateRequest,
  confirmRequest,
  getItemExtraInfo,
  updateBundleStatus,
  submitFeedback,
  getItemsFieldsTrue,
  setBundleConfirmed,
  updateBundleRejectRequest,
  getMyGiveItemByBundle,
  getMyGetItemByBundle,
  getItemFlagTypes,
  flagItem,
  getNotifications,
  updateNotificationById,
  updateNotifications,
  getNotificationCount,
  getItemCategory,
  getItemFieldSearchOption,
  getSelectedItemSearch,
  createBundleV2,
  createItemV2,
  getMyGivesListV2
};
