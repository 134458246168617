import axios from 'axios'


export const categoryApi = {
    getCategoryData
};



async function getCategoryData(successCallBack, errorCallBack) {
    // let request1 = axios.get('/api/v2/core/category'),
    //     request2 = axios.get('/api/v2/core/master/item-categories-fields/');

    // await axios.all([request1, request2]).then(axios.spread((...responses) => {
    //     successCallBack(responses[0].data, responses[1].data);
    // }));

    await axios.get('/api/v2/core/category').then((response) => {
        successCallBack(response.data);
    });
}

