import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import throttle from "lodash/throttle";

import { itemApi } from "./../../../api/itemApi";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  // hover: {
  //   '&:hover':{
  //       backgroundColor: 'rgba(127,204,40,0.12)'
  //   },
  // },
  MuiAutocompleteOption: {
    '&:hover': {
      backgroundColor: 'rgba(127,204,40,0.12)'
    },
  },
}));

export default function Search(props) {
  const classes = useStyles(),
    [inputValue, setInputValue] = React.useState(""),
    [options, setOptions] = React.useState([]),
    [isSelected, setIsSelected] = React.useState(""),
    handleChange = (event) => {
      setInputValue(event.target.value);
    };

  const handleSelectChange = (event, newValue) => {
    if (newValue == null) {
      return;
    } else {
      const uniqueId = newValue && newValue.unique_id;
      if (uniqueId) {
        props.updateLoading(true);
        props.setSearchTitle(newValue);
        setIsSelected(true);

        itemApi.getSelectedItemSearch(
          props.slug,
          uniqueId,
          function (response) {
            if (response) {
              props.updateLoading(false);
              props.setItemDetails(response.data);
              props.formikFields.setValues({ ...props.formikFields.values, title: response.data.title });
              props.setItem({ ...props.item, title: response.data.title, search_id: response.data.id });
            }
          }
        );
      } else if (newValue.id) {
        props.setSearchTitle(newValue.value);
        props.notYourItem(newValue.value);
      }
    }
  };

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        const categorySlug = props.slug;
        if (input.input.length < 4) {
          return;
        }

        itemApi.getItemFieldSearchOption(
          categorySlug,
          input.input,
          function (response) {
            return callback(response.data);
          }
        );
      }, 200),
    []
  );

  // handle the 'x' close button,
  // will clear the auto search data.
  // and searched string
  const handleClose = () => {
    setInputValue("");
    setOptions([]);
  };

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        results.push({
          id: -1,
          title: "Add item manually.",
          value: inputValue,
        });
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id={props.name}
      name={props.name}
      freeSolo={true} //TODo, remove it later {inputValue && inputValue.length >= 4 ? false : true}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.title && option.title.length > 45
            ? option.title.substring(0, 45) + "..."
            : option.title
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      disableOpenOnFocus
      closeIcon={<CloseIcon fontSize='small' onClick={e => { props.clearAll() }} />}
      loading={true}
      loadingText='Search Author or Title'
      onChange={handleSelectChange}
      value={props.searchTitle}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          fullWidth
          name={props.name}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          margin='normal'
          onChange={handleChange}
          onClick={handleClose}
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems='center' className={classes.hover}>
            <Grid item xs>
              <Typography variant='subtitle2' color='textSecondary' style={{ color: '#383838' }} >
                <span>{option.title && option.title}</span>
                <br />
                <span style={{ fontSize: "10px" }}>{option.subtitle}</span>
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
