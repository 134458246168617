import { makeStyles, fade } from '@material-ui/core/styles';

const ApplicationBarStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff',

        [theme.breakpoints.down('sm')]: {
            marginTop: '75px',

            '& .MuiAppBar-root': {
                display: 'none'
            }
        }
    },
    tabContainer: {
        padding: '0',
        margin: '0 auto',
        maxWidth: '1200px',

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        '& .MuiBox-root': {
            padding: 0
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset'
        }
    },
    menu: {
        margin: '0 auto',
        maxWidth: '1200px',

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        }
    },
    searchBarSection: {
        display: 'flex',
        alignItems: 'center'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
    },
    inputRoot: {
        color: '#fff',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    verifyEmailSection: {
        boxSizing: 'border-box',
        margin: '0 auto',
        minWidth: '650px',
        maxWidth: '1200px',
        letterSpacing: '1px',
        backgroundColor: 'aliceblue',
        padding: '15px',
        color: "rgb(13, 60, 97)",
        '& .MuiTypography-subtitle2': {
            fontWeight: '600 !important'
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset'
        }
    },
    mobileAppBar: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            position: 'fixed',
            top: '55px',
            left: '0px',
            right: '0px',
            background: '#fff',
            height: '103px',
            paddingTop: '30px',
            zIndex: '1001'
        }
    },
    mobileAppBarTabWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
    },
    mobileAppBarTab: {
        height: '56px',
        width: '57px',
        borderRadius: '15px',
        color: 'rgba(27,27,27,0.7)',
        fontFamily: "Open Sans",
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '17px',
        textAlign: 'center'
    },
    mobileAvatarBlue: {
        backgroundColor: 'rgba(45,114,204,0.1)'
    },
    mobileAvatarPeach: {
        backgroundColor: 'rgba(235,147,60,0.15)'
    },
    mobileAvatarPink: {
        backgroundColor: 'rgba(208,0,6,0.1)'
    },
    mobileAvatarOrange: {
        backgroundColor: 'rgba(255,185,0,0.15)'
    },
    mobileAvatarBlue: {
        backgroundColor: 'rgba(45,114,204,0.1)'
    },
    avatarSmallFonts: {
        fontSize: '11px'
    },
    activeAvatar: {
        border: '1px solid #22B11F',
        color: '#22B11F',
        backgroundColor: 'rgba(34,177,31,0.06)',
        boxShadow: '0 0 31px 0 rgba(34,177,31,0.18)'
    },
    mobileDisabled: {
        color: '#22B11F',
        backgroundColor: 'rgba(34,177,31,0.06)',
    }
}));

export default ApplicationBarStyles;