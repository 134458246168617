import React from 'react';
import { navigate } from '@reach/router';
import { GiveBooks } from './give/GiveBooks';
import { GiveForm } from './give/forms/GiveForm';
import { useStoreState } from './../../hooks';
import { Typography, Button, makeStyles, Card, CardActions, CardContent } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
}));

const GiveItem = (props) => {

    const classes = useStyles(),
        user = useStoreState((state) => state.auth.user),
        [page, setPage] = React.useState(props.slug),
        [bundle_id, setBundleId] = React.useState(props.bundle_id ? props.bundle_id : '');

    React.useLayoutEffect(() => {
    }, []);

    React.useEffect(() => {
        if (!user) {
            navigate('/');
            return;
        }
    }, []);

    return (
        <>
            <GiveForm {...props} />
        </>
    );
};

export { GiveItem };
