import React from 'react';
import PropTypes from 'prop-types';
import ApplicationBarStyles from "./../../styles/ApplicationBar.styles";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useStoreActions, useStoreState } from './../../hooks';
import { securityApi, categoryApi } from '../../api';
import { navigate } from '@reach/router';
import Avatar from '@material-ui/core/Avatar';
import { HorizontalAds } from "../ui/ads/HorizontalAds";
import { fieldToInputBase } from './InputBase';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ApplicationBar(props) {
  const [value, setValue] = React.useState(false),
    classes = ApplicationBarStyles(),
    user = useStoreState(state => state.auth.user),
    updateUser = useStoreActions(actions => actions.auth.updateUser),
    updateCategoryData = useStoreActions(actions => actions.category.set),
    propsBundleId = props.bundleId ? props.bundleId : '',
    isVerifyEmailSent = useStoreState(state => state.auth.isVerifyEmailSent),
    isJustRegistered = useStoreState(state => state.auth.isJustRegistered),
    [bundle_id, setBundleId] = React.useState(propsBundleId),
    handleChange = (event, newValue) => {
      if (event.target.hasAttribute('disabled')) {
        return false
      }

      switch (newValue) {
        case 0:
          navigate("/my-gives/1");
          break;
        case 1:
          navigate("/my-gets/1");
          break;
        case 2:
          navigate("/give/books");
          break;
        case 3:
          navigate("/get");
          break;
        case 4:
          navigate("/covid-19");
          break;
      }
      setBundleId('');
      securityApi.checkSession(user, updateUser);
    };

  React.useEffect(() => {
    let path = window.location.pathname;

    if (path.startsWith('/my-gives')) {
      setValue(0);
    } else if (path.startsWith('/my-gets')) {
      setValue(1);
    } else if (path.startsWith('/give')) {
      setValue(2);
    } else if (path.startsWith('/get')) {
      setValue(3);
    } else if (path.startsWith('/covid-19')) {
      setValue(4);
    }
    else {
      setValue(false);
    }
  }, [][value]);

  return (
    <div className={classes.root}>
      <div className={classes.mobileAppBar}>
        <div className={classes.mobileAppBarTabWrapper}>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarBlue} 
              ${value === 0 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}
            `}
            onClick={evt => handleChange(evt, 0)} disabled={user ? false : true}>
            My<br />Gives
          </Avatar>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarPeach} 
              ${value === 1 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}
            `}
            onClick={evt => handleChange(evt, 1)} disabled={user ? false : true}>
            My<br /> Gets
          </Avatar>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarPink} 
              ${value === 2 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}
            `}
            onClick={evt => handleChange(evt, 2)} disabled={user ? false : true}>
            Give
          </Avatar>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarOrange} 
              ${value === 3 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}  
            `}
            onClick={evt => handleChange(evt, 3)}>
            Get
          </Avatar>
        </div>
      </div>
      <AppBar position="static">
        <Grid className={classes.menu} container>
          <Grid item xs={12}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="My Gives" {...tabProps(0)} disabled={user ? false : true} />
              <Tab label="My Gets" {...tabProps(1)} disabled={user ? false : true} />
              <Tab label="Give" {...tabProps(2)} disabled={user ? false : true} />
              <Tab label="Get" {...tabProps(3)} />
              {/* <Tab label="Support a Cause" {...tabProps(4)} /> */}
              <Tab label="COVID-19" {...tabProps(4)} style={{ letterSpacing: '1px' }} />
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>
      {!isVerifyEmailSent && !isJustRegistered && user && (!user.u_status || user.u_status == 1) &&
        <section className={classes.verifyEmailSection}>
          <Box style={{ display: 'flex', justifyContent: 'center', }}>
            <Typography variant='subtitle2' style={{ marginLeft: '0.5rem' }} component='div'>
              {user && user.email ? <>Please confirm your email sent to  <Typography variant='subtitle2' component='span' style={{ color: '#22B11F' }}>{user.email}.</Typography></> : "Please confirm your email."}
            </Typography>
          </Box>
        </section>}
      {window.location.pathname !== '/dashboard' && <Box width='100%'> <HorizontalAds></HorizontalAds> </Box>}
      <TabPanel className={classes.tabContainer} value={value} index={0}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={1}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={2}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={3}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={4}>
        <TabPanel className={classes.tabContainer} value={value} index={5}>
        </TabPanel>
      </TabPanel>
    </div>
  );
}

export { ApplicationBar }