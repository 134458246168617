import * as React from "react";
import { navigate } from "@reach/router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { securityApi } from "./../../../../api/securityApi";
import { userApi } from "./../../../../api/userApi";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Link,
  Card,
  Button,
  Typography,
  Checkbox,
} from "@material-ui/core";
import * as yup from "yup";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { FormikTextField } from "../../../ui/FormikTextField";
import { appSettings } from "../../../../constants";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  Check,
  Clear,
  ErrorOutlineSharp,
  ReportProblemOutlined,
} from "@material-ui/icons";

const LoginStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  card: {
    boxSizing: "border-box",
    display: "flex",
    backgroundColor: "transparent",
    boxShadow: "none !important",
    alignItems: "center",
    height: "100vh",
    minHeight: "900px",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#ffffff",
      padding: "0 15px",
      minHeight: "unset",
      height: "unset",
    },
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    width: "460px",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      height: "100%",
    },
  },
  title1: {
    color: "#505050",
    fontSize: "30px",
    marginBottom: "30px",
    textAlign: "center",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
      marginBottom: "unset",
    },
  },
  or: {
    display: "flex",
    margin: "1rem 0",

    "&::after": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },

    "&::before": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },
  },
  fbIconContainer: {
    background: "#263C68",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  iconContainer: {
    background: "#FFF",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  formControlLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#717171",
      fontFamily: "Open Sans",
      fontSize: "14px",
      letterSpacing: " 0",
      // lineHeight: '16px',
      // paddingLeft: '5px',
      marginTop: "5px",
    },
    "& .MuiSvgIcon-root": {
      width: "18px",
    },
  },
  passwordRulesDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  passwordRuleIcon: {
    width: "1.25rem",
  },
  passwordRuleText: {
    marginLeft: "5px",
  },
}));

const schema = yup.object().shape({
  user: yup.object().shape({
    name: yup.string().required("Please enter name"),
  }),
  Email: yup.string().email("Please enter a valid email").required(),
  Password: yup
    .string()
    .min(8, " ")
    .matches(/[A-Z]/, " ")
    .matches(/[0-9]/, " ")
    .required("Please enter a valid password"),
});

const RegisterForm = () => {
  const [isTermAccepted, setTermAccepted] = React.useState(false),
    classes = LoginStyles(),
    login = useStoreActions((actions) => actions.auth.login),
    fb_app_id = appSettings.fb_app_id,
    google_client_id = appSettings.google_client_id,
    redirect_uri = appSettings.SOCIAL_REDIRECT_URL,
    [loading, setLoading] = React.useState(false),
    [passwordFocused, setPasswordFocused] = React.useState(false),
    updateJustRegistered = useStoreActions(
      (actions) => actions.auth.updateJustRegistered
    ),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);

  const [passwordMinCharRule, setPasswordMinCharRule] = React.useState(false);
  const [passwordUpperCaseRule, setPasswordUpperCaseRule] =
    React.useState(false);
  const [passwordNumberRule, setPasswordNumberRule] = React.useState(false);

  React.useEffect(() => {
    if (isLoggedIn === true) {
      navigate("/");
    }
  }, []);

  const checkPasswordValidation = (password) => {
    setPasswordMinCharRule(password.length >= 8);
    setPasswordUpperCaseRule(/[A-Z]/.test(password));
    setPasswordNumberRule(/[0-9]/.test(password));
  };

  return (
    <>
      <Card className={classes.card} style={{ width: "100%" }}>
        <section className={classes.loginContainer}>
          <Typography component="div" className={classes.title1}>
            Sign up with Kindeeds
          </Typography>
          <Formik
            initialValues={{
              Email: "",
              Password: "",
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true);

              grecaptcha.ready(function () {
                grecaptcha
                  .execute("6LcB-KgZAAAAAKZPf3Nc51ZNSEQB77y-7987apn-", {
                    action: "submit",
                  })
                  .then(function (token) {
                    userApi.recaptcha(
                      { g_recaptcha_response: token },
                      function (response) {
                        if (response.verified) {
                          try {
                            const requestDataBlk = {
                              full_name: values.user.name,
                              email: values.Email.toLowerCase(),
                              password1: values.Password,
                              password2: values.Password,
                            };

                            securityApi.userSignup(
                              requestDataBlk,
                              function () {
                                // commented the auto login after registration
                                const requestDataBlk = {
                                  email: values.Email,
                                  password: values.Password,
                                };
                                securityApi.login(
                                  requestDataBlk,
                                  function (response) {
                                    setLoading(false);
                                    login({
                                      token: response.key,
                                      user: response.data,
                                    });
                                    updateJustRegistered({
                                      isJustRegistered: true,
                                    });
                                    navigate("/dashboard", {
                                      replace: true,
                                      state: { isRegistered: true },
                                    });
                                  }
                                );
                              },
                              function () {
                                setLoading(false);
                              }
                            );
                          } catch ({ error }) {}
                          setSubmitting(false);
                        } else {
                          setLoading(false);
                        }
                      }
                    );
                  });
              });
            }}
            render={({ errors, touched, submitForm, isSubmitting, values }) => (
              <Form style={{ width: "100%" }} align="Left">
                <input
                  type="hidden"
                  id="g-recaptcha-response"
                  name="g-recaptcha-response"
                />
                <input type="hidden" name="action" value="validate_captcha" />
                <Field
                  type="text"
                  label="Name"
                  name="user.name"
                  autoComplete="new-username"
                  fullWidth
                  style={{ margin: "1rem 0" }}
                  component={FormikTextField}
                  onFocus={(e) => {
                    setPasswordFocused(false);
                  }}
                />
                <Field
                  type="email"
                  label="Email"
                  name="Email"
                  fullWidth
                  style={{ margin: "1rem 0" }}
                  component={FormikTextField}
                  onFocus={(e) => {
                    setPasswordFocused(false);
                  }}
                />

                <Field
                  type="password"
                  label="Password"
                  name="Password"
                  autoComplete="new-password"
                  fullWidth
                  style={{ margin: "1rem 0" }}
                  component={FormikTextField}
                  onFocus={(e) => {
                    setPasswordFocused(true);
                  }}
                  onBlur={(e) => {
                    setPasswordFocused(false);
                  }}
                  onKeyUp={(e) => {
                    checkPasswordValidation(e.target.value);
                  }}
                />
                <Alert
                  severity="info"
                  style={{ display: passwordFocused ? "flex" : "none" }}
                >
                  <AlertTitle>Password must contain:</AlertTitle>
                  <div>
                    <div className={classes.passwordRulesDiv}>
                      {passwordMinCharRule ? (
                        <Check
                          color="primary"
                          className={classes.passwordRuleIcon}
                        />
                      ) : (
                        <Clear
                          color="error"
                          className={classes.passwordRuleIcon}
                        />
                      )}
                      <Typography
                        variant="subtitle2"
                        className={classes.passwordRuleText}
                      >
                        Minimum 8 Characters
                      </Typography>
                    </div>
                    <div className={classes.passwordRulesDiv}>
                      {passwordUpperCaseRule ? (
                        <Check
                          color="primary"
                          className={classes.passwordRuleIcon}
                        />
                      ) : (
                        <Clear
                          color="error"
                          className={classes.passwordRuleIcon}
                        />
                      )}
                      <Typography
                        variant="subtitle2"
                        className={classes.passwordRuleText}
                      >
                        At least 1 Uppercase Letter
                      </Typography>
                    </div>
                    <div className={classes.passwordRulesDiv}>
                      {passwordNumberRule ? (
                        <Check
                          color="primary"
                          className={classes.passwordRuleIcon}
                        />
                      ) : (
                        <Clear
                          color="error"
                          className={classes.passwordRuleIcon}
                        />
                      )}
                      <Typography
                        variant="subtitle2"
                        className={classes.passwordRuleText}
                      >
                        At least 1 Number
                      </Typography>
                    </div>
                    <div className={classes.passwordRulesDiv}>
                      <ReportProblemOutlined
                        className={classes.passwordRuleIcon}
                      />
                      <Typography
                        variant="subtitle2"
                        className={classes.passwordRuleText}
                      >
                        Avoid Common Words
                      </Typography>
                    </div>
                  </div>
                </Alert>
                <Box style={{ display: "flex", alignItems: "start" }} mt={2}>
                  <Checkbox
                    size="small"
                    onClick={(e) => {
                      setTermAccepted(e.target.checked);
                      setPasswordFocused(false);
                    }}
                    className={classes.formControlLabel}
                    style={{ marginRight: "5px" }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ color: " #505D68", fontSize: "14px" }}
                  >
                    By registering, I accept the site
                    <Link href="/termsofuse" target="_newWindow">
                      {" "}
                      Terms of Use{" "}
                    </Link>{" "}
                    and
                    <Link href="/privacypolicy" target="_newWindow">
                      {" "}
                      Privacy Policy
                    </Link>
                  </Typography>
                </Box>
                <div
                  className={classes.wrapper}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ marginTop: "30px" }}
                    color="primary"
                    disabled={
                      loading ||
                      !(touched.Email && touched.Password) ||
                      Object.keys(errors).indexOf("Email") > -1 ||
                      Object.keys(errors).indexOf("Password") > -1 ||
                      !isTermAccepted
                    }
                  >
                    Sign Up
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          />
          <Typography
            variant="subtitle1"
            component="div"
            className={classes.or}
          >
            OR
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            style={{ alignSelf: "center", marginBottom: "1rem" }}
          >
            Continue with
          </Typography>
          <Box
            display="flex"
            alignSelf="center"
            mb={4}
            className={!isTermAccepted ? classes.disabled : ""}
          >
            <div className={classes.fbIconContainer}>
              <img
                src="/assets/images/FB.svg"
                width="20px"
                height="20px"
                style={{ margin: "10px" }}
                onClick={(e) => {
                  navigate(
                    "https://graph.facebook.com/oauth/authorize?client_id=" +
                      fb_app_id +
                      "&redirect_uri=" +
                      redirect_uri +
                      "&scope=email&response_type=code"
                  );
                }}
              ></img>
            </div>
            <div
              className={classes.iconContainer}
              style={{ marginTop: "-3px" }}
            >
              <img
                src="/assets/images/btn_google_light_normal_ios.svg"
                onClick={(e) => {
                  navigate(
                    "https://accounts.google.com/o/oauth2/auth?redirect_uri=" +
                      redirect_uri +
                      "&response_type=code&client_id=" +
                      google_client_id +
                      "&scope=https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email&approval_prompt=force&access_type=offline"
                  );
                }}
              ></img>
            </div>
          </Box>
          <Typography
            variant="subtitle2"
            style={{ alignSelf: "center", marginTop: "1rem" }}
          >
            {" "}
            or <Link href="/login">Sign-in</Link> to your account
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            style={{ alignSelf: "center", margin: "1rem", textAlign: "left" }}
          >
            This site is protected by reCAPTCHA and the Google{" "}
            <Link
              href="https://policies.google.com/privacy"
              target="_newWindow"
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link href="https://policies.google.com/terms" target="_newWindow">
              Terms of Service
            </Link>{" "}
            apply.
          </Typography>
        </section>
      </Card>
    </>
  );
};

export { RegisterForm };
