import React from 'react';
import Search from './../../src/components/ui/give/Search';
import { Field } from 'formik';
import { FormikTextField } from './../components/ui/FormikTextField';
import { FormikSearchTextField } from './../components/ui/FormikSearchTextField';
import DragDropImage from './../../src/components/ui/dragDropImage';
import {
    Box
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';

export const categoryHelper = {
    getSearchField,
    getImageField,
    getTextField,
    getTextAreaField,
    getAutocompleteField,
    getNumberField
};

const useStyles = makeStyles((theme) => ({
    mobileAutoComplete: {
        [theme.breakpoints.down('sm')]: {
            ' & input': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                }
            }
        }
    },
    helperTextRight: {
        display: 'flex',
        alignSelf: 'flex-end'
    },
    mobileFormLabel: {
        '& .MuiFormLabel-root': {
            [theme.breakpoints.down('sm')]: {
                color: '#AAAAAA',
                fontFamily: "Open Sans",
                fontSize: '14px',
                letterSpacing: '0',
                lineHeight: '18px',
            }
        },
        '& .MuiFormLabel-root.Mui-focused': {
            [theme.breakpoints.down('sm')]: {
                color: '#22B11F'
            }
        }
    },
}));


function getSearchField(key, data, props, formikFields) {
    return (
        <> {props.isItemNotCorrect ? <Box mt={2}>
            <Field
                fullWidth
                type='text'
                label={data.label}
                name={key}
                value={
                    props.item && props.item[key].length > 40
                        ? props.item[key].substring(0, 40) + '...'
                        : props.item[key]
                }
                component={FormikTextField}
                onChange={(e) => {
                    formikFields.setFieldValue(key, e.target.value);
                    props.setItem({ ...props.item, [key]: e.target.value });
                }} />
        </Box> : <Box>
            <Search label={data.label}
                name={key}
                fieldId={key}
                formikFields={formikFields}
                {...props} />
        </Box>}


        </>
    );
}

function getImageField(props, formikFields) {
    return (
        <DragDropImage style={{ minHeight: '160px', overflow: 'unset' }}
            key={props.resetKey}
            formikFields={formikFields}
            setItem={props.setItem}
            item={props.item} />
    );
}

function getTextField(key, data, props, formikFields) {
    return (
        <Box mt={2} className={data.validations.hidden ? 'd-none' : 'd-block'}>
            <Field fullWidth type='text'
                label={data.label}
                name={key}
                placeholder={data.help_text}
                value={props.item[key] || ''}
                onChange={(e, value) => {
                    formikFields.setFieldValue(key, e.currentTarget.value);
                    props.setItem({
                        ...props.item,
                        [key]: e.currentTarget.value,
                    });
                }}
                component={FormikTextField} />
        </Box>
    );
}

function getTextAreaField(key, data, props, formikFields) {
    const classes = useStyles();

    return (
        <Box mt={2}>
            <Field
                multiline
                label={data.label}
                placeholder={data.help_text}
                rows={data.validations.rows}
                name={key}
                fullWidth
                inputProps={{ maxLength: 200 }}
                value={props.item[key] || ''}
                component={FormikTextField}
                className={classes.mobileFormLabel}
                onChange={(e) => {
                    formikFields.setFieldValue(key, e.currentTarget.value);
                    props.setItem({
                        ...props.item,
                        [key]: e.currentTarget.value,
                    });
                }}
                FormHelperTextProps={{ classes: { root: classes.helperTextRight } }}
                helperText={`${formikFields.values[key] ? formikFields.values[key].length : 0}/200`}
            />
        </Box>
    );
}

function getAutocompleteField(key, data, props, formikFields) {
    const classes = useStyles();
    return (
        <Box mt={2}>
            <Autocomplete
                options={data.validations[0].options || []}
                getOptionLabel={(option) => option}
                // getOptionDisabled={(option) =>
                //     option.value === 'Needs Repair'
                // }
                className={classes.mobileAutoComplete}
                onChange={(e, value) => {
                    formikFields.setFieldValue(key, value);
                    props.setItem({
                        ...props.item,
                        [key]: value,
                    });
                }}
                name={key}
                placeholder={data.help_text}
                value={props.item[key] || null}
                renderInput={(params) => (
                    <Field
                        fullWidth
                        {...params}
                        label={data.label}
                        name={key}
                        component={FormikSearchTextField}
                        className={classes.mobileFormLabel}
                    />
                )}
            />
        </Box>
    );
}

function getNumberField(key, data, props, formikFields) {
    const classes = useStyles();

    return (
        <Box mt={2}>
            <Field
                fullWidth
                type='number'
                value={props.item[key] || ''}
                label={data.label}
                placeholder={data.help_text}
                onChange={(e) => {
                    formikFields.setFieldValue(key, e.currentTarget.value);
                    props.setItem({
                        ...props.item,
                        [key]: e.currentTarget.value,
                    });
                }}
                name={key}
                component={FormikTextField}
                className={classes.mobileFormLabel}
            />
            {/* {item.bookPublishYear && (
                <Typography
                    variant='subtitle2'
                    component='div'
                    style={{ color: '#EB933C' }}>
                    Please update year if different for your item.
                </Typography>
            )} */}
        </Box>
    );
}