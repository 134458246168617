import React from 'react'
import GiveBooksStyles from './../../../styles/GiveBooks.styles';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { itemApi } from "./../../../api/itemApi";
import { appSettings } from "../../../constants";
import Dialog from "@material-ui/core/Dialog/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function BundleItem(props) {

    const [confirmModel, setConfirmModel] = React.useState(false);
    const handleConfrimOpen = () => {
        setConfirmModel(true);
    };
    const handleConfrimClose = () => {
        setConfirmModel(false);
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const deleteItemFromList = (bundleId, ItemId) => {
        if (bundleId && ItemId) {

            itemApi.deleteItemFormBundle(bundleId, ItemId, function (resp) {
                if (resp) {
                    handleClose();
                    props.clearForm();
                    if (resp.items_count < 1) {
                        props.setBundleId('');
                        props.setItemId('');
                        props.setBundleList([]);
                    } else {
                        itemApi.getItemsByBundle(bundleId, function (response) {
                            props.itemUpdate(response.Items);
                        });
                    }
                }
            });
        }
    }
    const editItem = (editedItem) => {
        if (editedItem) {
            handleClose()
            props.itemEdit(editedItem);
        }
    }
    const classes = GiveBooksStyles();

    let img = props.item.upload_images[0].startsWith("http") ? props.item.upload_images[0] : appSettings.MEDIA_URL + props.item.upload_images[0];
    return (
        <>
            <Card className={`${classes.card} ${classes.noMargin}`}>
                <img className={classes.cover} src={img} alt="Item image" />
                <div style={{ width: '100%' }}>
                    <CardHeader
                        action={
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            width: 100,
                                        },
                                    }}>
                                    <MenuItem onClick={() => editItem(props.item)}>Edit</MenuItem>
                                    <MenuItem onClick={() => { handleConfrimOpen() }}>Delete</MenuItem>
                                </Menu>
                            </div>
                        }
                        style={{
                            padding: '0 0 0 16px',
                            alignItems: 'end'
                        }}
                        title={<Typography variant='h5' style={{ color: '#2D72CC'}} component='div'>{props.slug}</Typography>}
                        subheader={<Typography variant='h2' component='div'>{props.item.title}</Typography>}
                    />
                    <CardContent>
                    </CardContent>
                </div>
                <Dialog onClose={handleConfrimOpen} aria-labelledby="customized-dialog-title" open={confirmModel} maxWidth='xs'>
                    <DialogTitle id="customized-dialog-title" onClose={handleConfrimClose} style={{ color: "#7FCC28" }}>
                        Confirm
                    </DialogTitle>
                    <DialogContent>
                        Do you want to delete the selected item?
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' type='submit' color="primary" onClick={e => { handleClose(); deleteItemFromList(props.item.bundle_id, props.item.item_id) }}>Yes</Button>
                        <Button variant='outlined' color='primary' onClick={e => { handleClose(); handleConfrimClose() }}>No</Button>
                    </DialogActions>
                </Dialog>
            </Card >
        </>
    )
}

export default BundleItem
