import * as yup from "yup";

export const validationsHelper = {
    getYupValidations
};

function getBookValidations() {
    return yup.object().shape({
        title: yup.string().required('Please enter book title.').nullable(),
        condition: yup.string().required('Please select book condition.').nullable(),
        book_type: yup.string().required('Please select book type.').nullable(),
        year: yup.number().positive('Year must be a positive number.').nullable()
            .test({
                name: 'isYearValid',
                test: function (value) {
                    if (value) {
                        let len = value.toString().length;
                        let currentYear = new Date().getFullYear();
                        if (len != 4) {
                            return this.createError({
                                message: `Please enter a valid year.`,
                                path: 'year', // Fieldname
                            })
                        } else if (value > currentYear) {
                            return this.createError({
                                message: `Please enter year less than or equal to the current year.`,
                                path: 'year', // Fieldname
                            })
                        }
                        else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }).nullable(),
        genre: yup.string().required('Please select genre type.').nullable(),
        // isbn: yup.string().nullable(),
        // author: yup.string().required('Please enter author.').nullable(),
        // edition: yup.string().nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        language: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getGadgetValidations() {
    return yup.object().shape({
        name: yup.string().required('Please enter gadget name.')
            .min(2, 'Name length greater than 2 characters.')
            .max(50, 'Name length must be less than 50 charcters.').nullable(),
        condition: yup.string().required('Please select gadget condition.').nullable(),
        gadget_type: yup.string().required('Please select gadget type.').nullable(),
        sub_type: yup.string().required('Please select gadget sub-type.').nullable(),
        brand: yup.string().required('Please select gadget brand').nullable(),
        genre: yup.string().required('Please select genre.').nullable(),
        display_size: yup.string().required('Please select display size.').nullable(),
        display_type: yup.string().required('Please select display type.').nullable(),
        memory: yup.string().required('Please select display memory.').nullable(),
        storage: yup.string().required('Please select storage.').nullable(),
        storage_type: yup.string().required('Please select storage type.').nullable(),
        cpu_processor: yup.string().required('Please select cpu processor.').nullable()
    });
}

function getMusicValidations() {
    return yup.object().shape({
        title: yup.string().required('Please enter few letters of title.').nullable(),
        condition: yup.string().required('Please select book condition.').nullable(),
        artist: yup.string().required('Please enter comma separated artist names.').nullable(),
        album: yup.string().required('Please enter album name.').nullable(),
        year: yup.number().positive('Year must be a positive number.').nullable()
            .test({
                name: 'isYearValid',
                test: function (value) {
                    if (value) {
                        let len = value.toString().length;
                        let currentYear = new Date().getFullYear();
                        if (len != 4) {
                            return this.createError({
                                message: `Please enter a valid year.`,
                                path: 'year', // Fieldname
                            })
                        } else if (value > currentYear) {
                            return this.createError({
                                message: `Please enter year less than or equal to the current year.`,
                                path: 'year', // Fieldname
                            })
                        }
                        else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }).nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getClothValidations() {
    return yup.object().shape({
        name: yup.string().required('Please enter cloth name.')
            .min(3, 'Name length greater than 3 characters.')
            .max(50, 'Name length must be less than 50 charcters.').nullable(),
        brand: yup.string().required('Please enter cloth brand name.')
            .min(3, 'Brand name length greater than 3 characters.')
            .max(50, 'Brand name length must be less than 50 charcters.').nullable(),
        gender: yup.string().required('Please select gender.'),
        condition: yup.string().required('Please select cloth condition.').nullable(),
        type: yup.string().required('Please select cloth type.').nullable(),
        sub_type: yup.string().required('Please select cloth sub-type.').nullable(),
        size: yup.string().required('Please select size.').nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getGameValidations() {
    return yup.object().shape({
        name: yup.string().required('Please enter name of game.')
            .min(2, 'Name length greater than 2 characters.')
            .max(50, 'Name length must be less than 50 charcters.').nullable(),
        brand: yup.string().required('Please enter brand name.')
            .min(3, 'Brand name length greater than 3 characters.')
            .max(50, 'Brand name length must be less than 50 charcters.').nullable(),
        age: yup.string().required('Please select age group.'),
        condition: yup.string().required('Please select condition.').nullable(),
        type: yup.string().required('Please select game type.').nullable(),
        sub_type: yup.string().required('Please select game sub-type.').nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getSportValidations() {
    return yup.object().shape({
        name: yup.string().required('Please enter name of sport good.')
            .min(2, 'Sports Good name must be greater than 2 characters.')
            .max(50, 'Sports Good name must be less than 50 characters.').nullable(),
        brand: yup.string().required('Please enter name of sport brand.')
            .min(2, 'Brand name length greater than 2 characters.')
            .max(50, 'Brand name length must be less than 50 charcters.').nullable(),
        gender: yup.string().required('Please select gender.'),
        condition: yup.string().required('Please select sport good condition.').nullable(),
        type: yup.string().required('Please select sport good type.').nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getMovieValidations() {
    return yup.object().shape({
        title: yup.string().required('Please enter few letters of Titles.').nullable(),
        condition: yup.string().required('Please select book condition.').nullable(),
        artist: yup.string().required('Please enter comma separated artist names.').nullable(),
        year: yup.number().positive('Year must be a positive number.').nullable()
            .test({
                name: 'isYearValid',
                test: function (value) {
                    if (value) {
                        let len = value.toString().length;
                        let currentYear = new Date().getFullYear();
                        if (len != 4) {
                            return this.createError({
                                message: `Please enter a valid year.`,
                                path: 'year', // Fieldname
                            })
                        } else if (value > currentYear) {
                            return this.createError({
                                message: `Please enter year less than or equal to the current year.`,
                                path: 'year', // Fieldname
                            })
                        }
                        else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }).nullable(),
        genre: yup.string().required('Please select movie genre.').nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getShoeValidations() {
    return yup.object().shape({
        name: yup.string().required('Please enter name of shoe.')
            .min(2, 'Name length greater than 2 characters.')
            .max(50, 'Name length must be less than 50 charcters.').nullable(),
        brand: yup.string().required('Please enter shoe brand.')
            .min(2, 'Brand name length greater than 2 characters.')
            .max(50, 'Brand name length must be less than 50 charcters.').nullable(),
        gender: yup.string().required('Please select gender.'),
        condition: yup.string().required('Please select shoe condition.').nullable(),
        type: yup.string().required('Please select shoe type.').nullable(),
        sub_type: yup.string().required('Please select shoe sub-type.').nullable(),
        size: yup.string().required('Please Select Size.')
            .min(1, 'Shoe size must be greater than 1')
            .max(12, 'Shoe size must be less than 12').nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getMagazineValidations() {
    return yup.object().shape({
        title: yup.string().required('Please enter book title.').nullable(),
        condition: yup.string().required('Please select book condition.').nullable(),
        book_type: yup.string().required('Please select book type.').nullable(),
        year: yup.number().positive('Year must be a positive number.').nullable()
            .test({
                name: 'isYearValid',
                test: function (value) {
                    if (value) {
                        let len = value.toString().length;
                        let currentYear = new Date().getFullYear();
                        if (len != 4) {
                            return this.createError({
                                message: `Please enter a valid year.`,
                                path: 'year', // Fieldname
                            })
                        } else if (value > currentYear) {
                            return this.createError({
                                message: `Please enter year less than or equal to the current year.`,
                                path: 'year', // Fieldname
                            })
                        }
                        else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }).nullable(),
        genre: yup.string().required('Please select genre type.').nullable(),
        // isbn: yup.string().nullable(),
        // author: yup.string().required('Please enter author.').nullable(),
        // edition: yup.string().nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        language: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getToyValidations() {
    return yup.object().shape({
        name: yup.string().required('Please enter name of toy.')
            .min(2, 'Name length greater than 2 characters.')
            .max(50, 'Name length must be less than 50 charcters.').nullable(),
        brand_character: yup.string().required('Please enter toy brand / character.')
            .min(2, 'Brand / character name length greater than 2 characters.')
            .max(50, 'Brand / character name length must be less than 50 charcters.').nullable(),
        gender: yup.string().required('Please select gender.'),
        age: yup.string().required('Please select age group.'),
        condition: yup.string().required('Please select shoe condition.').nullable(),
        type: yup.string().required('Please select shoe type.').nullable(),
        sub_type: yup.string().required('Please select shoe sub-type.').nullable(),
        website_url: yup.string().nullable()
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                'Please enter a valid URL.'
            ).nullable(),
        share_a_story: yup.string().nullable(),
        original_cost: yup.number().positive('Original cost must be a positive number.').nullable()
    });
}

function getYupValidations(slug) {
    switch (slug) {
        case 'Books':
            return getBookValidations();
        case 'Clothes':
            return getClothValidations();
        case 'Gadgets':
            return getGadgetValidations();
        case 'Games':
            return getGameValidations();
        case 'Movies':
            return getMovieValidations();
        case 'Shoes':
            return getShoeValidations();
        case 'Sports':
            return getSportValidations();
        case 'Toys':
            return getToyValidations();
        case 'Music':
            return getMusicValidations();
    }
}