import {apiHelper} from '../helpers';
import {httpMethodTypes} from "../constants/httpMethodTypes";
import {appSettings} from "../constants/appSettings";
import axios from 'axios'

export const shippingApi = {
    getAllPreferenceList,
    getShippingPreference,
    updateUserShippingPreference,
    getDeliveryPreference,
    getuserShippingAndDeliveryPreferences
};
const BASE_URL =  appSettings.BaseURL;
async function callGetMethod(url, successCallBack, errorCallBack){
    try {
        await axios.get(url).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
        })
    } catch (error) {
        if(errorCallBack){ errorCallBack({...error}) };
    }
}
async function callPutMethod(url, requestDataBlk, successCallBack, errorCallBack){
    try {
        await axios.put(url, requestDataBlk).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
        })
    } catch (error) {
        if(errorCallBack){ errorCallBack({...error}) }
    }
}

async function getAllPreferenceList(successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true, {})
    // const url = BASE_URL + 'api/shipping/all-preference-list/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callGetMethod('api/shipping/all-preference-list/', successCallBack, errorCallBack);
}

async function getShippingPreference(successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true, {})
    // const url = BASE_URL + 'api/shipping/master/shipping-preferences/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack);
    await callGetMethod('api/shipping/master/shipping-preferences/', successCallBack, errorCallBack);
}

async function updateUserShippingPreference(requestDataBlk, successCallBack, errorCallBack) {
    await callPutMethod('api/shipping/user/account/preferences/shipping/', requestDataBlk, successCallBack, errorCallBack);
}

async function getDeliveryPreference(successCallBack, errorCallBack) {
    await callGetMethod('api/shipping/master/delivery-preferences/', successCallBack, errorCallBack);
}

async function getuserShippingAndDeliveryPreferences(successCallBack, errorCallBack) {
    await callGetMethod('api/shipping/user/account/preferences/shipping/', successCallBack, errorCallBack);
}