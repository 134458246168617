import * as React from 'react';
import { navigate } from '@reach/router';
import { Button, Box, Card, Checkbox, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { securityApi } from './../../../../api/securityApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { useConfirmation } from './../../../../ConfirmationService';
import { appSettings } from "./../../../../constants/appSettings";
import { FormikTextField } from '../../../ui/FormikTextField';
import { userApi } from './../../../../api/userApi';

const LoginStyles = makeStyles(theme => ({
  root: {

    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    backgroundColor: '#ffffff',
    padding: '2rem'
  },
  card: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '100vh',
    minHeight: '900px',
    backgroundColor: "transparent",
    boxShadow: "none !important",
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "#ffffff",
      padding: '0 15px',
      minHeight: 'unset',
      height: 'unset'
    },
  },
  rectangle: {
    backgroundColor: 'rgba(127,204,40,0.03)',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem'
  },
  formControl: {
    width: '75%'
  },
  cover: {
    width: '66px',
    height: '66px'
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '460px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem'
    }
  },
  title1: {
    color: '#505050',
    fontSize: '30px',
    marginBottom: '30px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
      marginBottom: 'unset'
    }
  },
  or: {
    display: 'flex',
    margin: '2rem 0',

    '&::after': {
      content: `''`,
      flex: '1 1',
      borderBottom: '1px solid #E7E7E7',
      margin: 'auto 2rem'
    },

    '&::before': {
      content: `''`,
      flex: '1 1',
      borderBottom: '1px solid #E7E7E7',
      margin: 'auto 2rem'
    }
  },
  fbIconContainer: {
    background: '#263C68',
    height: '40px',
    margin: '0 10px',
    borderRadius: '4px',
    cursor: 'pointer'
  },

  iconContainer: {
    background: '#FFF',
    height: '40px',
    margin: '0 10px',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  }
}));

const schema = yup.object().shape({
  /*user: yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required(),
  }),*/
  Email: yup.string().email('Please enter a valid email').required(),
  Password: yup.string().required('Please enter a valid password'),
  remember: yup.boolean(),
});


const LoginForm = (props) => {
  const confirm = useConfirmation().bind(this),
    classes = LoginStyles(),
    user = useStoreState(state => state.auth.user),
    login = useStoreActions(actions => actions.auth.login),
    [rememberMe, setRememberMe] = React.useState(false),
    [localEmail, setLocalEmail] = React.useState(''),
    [loading, setLoading] = React.useState(false),
    fb_app_id = appSettings.fb_app_id,
    google_client_id = appSettings.google_client_id,
    redirect_uri = appSettings.SOCIAL_REDIRECT_URL;

  const openErrorDialog = (message) => {
    confirm({
      variant: 'info',
      catchOnCancel: true,
      title: 'Error',
      description: message
    });
  },
    handleRemember = (e) => {
      let checked = e.target.checked;
      setRememberMe(checked)
    };

  React.useEffect(() => {
    let localStoreEmail = localStorage.getItem('email');

    if (localStoreEmail) {
      setLocalEmail(localStoreEmail)
      setRememberMe(true)
    }
  }, []);

  return (
    <Card className={classes.card} style={{ width: '100%', display: 'flex' }}>
      <section className={classes.loginContainer}>
        <Typography component='div' className={classes.title1}>Welcome to Kindeeds</Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            Email: localEmail ? localEmail : '',
            Password: '',
            remember: false,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, setFieldError, submitForm }) => {
            setLoading(true);
            try {
              const requestDataBlk = {
                email: values.Email.toLowerCase(),
                password: values.Password
              }
              securityApi.login(requestDataBlk, function (response, resStatus) {
                login({ token: response.key, user: response.data });
                if (rememberMe) {
                  localStorage.setItem('email', response.data.email);
                } else {
                  localStorage.setItem('email', '');
                }
                setLoading(false);

                props && props.redirectUrl ? navigate(props.redirectUrl, { replace: true }) : navigate('/dashboard', { replace: true });
              }, function (response, resStatus) {
                setLoading(false);
              });
            } catch ({ error }) {
              openErrorDialog("Login Failed");
            }
          }}
          render={() => (
            <Form style={{ width: '100%' }}>
              <Field
                type="email"
                label="Email"
                name="Email"
                fullWidth
                style={{ margin: '1rem 0' }}
                align="Left"
                component={FormikTextField}
              />
              <Field
                type="password"
                label="Password"
                name="Password"
                align="Left"
                fullWidth
                component={FormikTextField}
              />
              <Box display='flex' alignItems='center' justifyContent='space-between' mb={6} mt={2}>
                <FormControlLabel
                  control={<Checkbox size="small" onClick={handleRemember} checked={rememberMe} name="rememberMe" id="ch" />}
                  label="Remember me" />
                <Typography variant='subtitle2' style={{ cursor: 'pointer', lineHeight: '24px' }} component='div' onClick={() => navigate('/reset-password')}>
                  <Link>Forgot password?</Link>
                </Typography>

              </Box>
              <div className={classes.wrapper} >
                <Button variant='contained' style={{ width: '146px' }} type='submit' disabled={loading} color='primary'>Sign In</Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </Form>
          )}
        />
        <Typography variant='subtitle1' component='div' className={classes.or}>OR</Typography>
        <Typography variant='subtitle2' component='div' style={{ alignSelf: 'center', marginBottom: '1rem' }}>Continue with</Typography>
        <Box display='flex' alignSelf='center' mb={4}>
          <div className={classes.fbIconContainer}>
            <img src='/assets/images/FB.svg' width='20px' height='20px' style={{ margin: '10px' }}
              onClick={e => {
                navigate("https://graph.facebook.com/oauth/authorize?client_id=" + fb_app_id + "&redirect_uri=" + redirect_uri + "&scope=email&response_type=code")
              }}>
            </img>
            <svg><use xlinkHref={`/assets/images/icons.svg#icon-add`}></use></svg>
          </div>
          <div className={classes.iconContainer} style={{ marginTop: '-3px' }}>
            <img src='/assets/images/btn_google_light_normal_ios.svg'
              onClick={e => {
                navigate("https://accounts.google.com/o/oauth2/auth?redirect_uri=" + redirect_uri + "&response_type=code&client_id=" + google_client_id + "&scope=https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email&approval_prompt=force&access_type=offline")

              }}
            ></img>
          </div>
        </Box>

        <Typography variant='subtitle2' component='div' style={{ alignSelf: 'center', marginTop: '1rem' }}>
          New to Kindeeds? <Link href='/register' color='primary'>Sign up</Link>
        </Typography>
        <Typography variant='subtitle1' component='div' style={{ alignSelf: 'center', margin: '1rem', textAlign: 'left' }}>
          This site is protected by reCAPTCHA and the Google <Link href="https://policies.google.com/privacy" target='_newWindow'>Privacy Policy</Link> and <Link href="https://policies.google.com/terms" target='_newWindow'>Terms of Service</Link> apply.</Typography>
      </section>
    </Card>
  );

}


export { LoginForm };