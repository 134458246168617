import { makeStyles } from '@material-ui/core/styles';

const GetStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    container: {
        backgroundColor: theme.palette.background.primary,

        '& .MuiGrid-grid-xs-2': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },

        '& .MuiGrid-grid-xs-10': {
            [theme.breakpoints.down('sm')]: {
                flexGrow: 0,
                maxWidth: '100%',
                flexBasis: '100%'
            }
        },

        "& .MuiExpansionPanel-root": {
            margin: "16px 0",
            "& .MuiAccordionSummary-root": {
                minHeight: "25px !important",
                height: "25px !important",
            },

            "& .MuiAccordionSummary-content": {
                margin: "0 0 20px 0 !important",

                "& :before": {
                    height: "3px",
                },
                "& .MuiTypography-root": {
                    marginTop: "16px"
                }
            },

            "& .MuiAccordionDetails-root": {
                padding: "1rem 0",
            },
        },
    },
    bannerSection: {
        height: '90px',
        padding: '20px 10px',
        backgroundColor: theme.palette.secondary.secondary,

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    fixedButtonSection: {
        backgroundColor: theme.palette.secondary.secondary,
        bottom: 0,
        position: 'fixed',
        zIndex: 2,
        padding: '1rem 0 !important',
        width: '100%',
        display: 'none',
        alignItems: 'center',

        '& .MuiButton-root': {
            margin: '0 10px',
            flex: '1 1',
            [theme.breakpoints.down('sm')]: {
                flex: '0 0'
            }
        },

        '& .MuiTypography-subtitle2': {
            flex: '1 1'
        },

        '& .MuiBadge-root': {
            alignSelf: 'baseline'
        },

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: '#fff',
            justifyContent: 'center'
        }
    },
    setSection: {
        padding: '4px',
        width: '77px',
        backgroundColor: theme.palette.primary.secondary
    },
    imageContainer: {
        width: '100%',
        height: '283px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease',
        backfaceVisibility: 'hidden',
        transform: 'translate3d(0, 0, 0)',
        cursor: 'pointer'
    },
    backButton: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',

        '&:hover': {
            fill: theme.palette.primary.main
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: '1rem'
        }
    },
    itemDetails: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',

            '& .MuiGrid-item': {
                flexBasis: '100%',
                maxWidth: '100%'
            }
        }
    },
    card: {
        height: '335px',
        width: '291px',
        boxSizing: 'border-box',
        margin: '0 20px 20px 0',
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #F2F5F7',

        [theme.breakpoints.down('sm')]: {
            margin: '1rem 0'
        },

        '&:hover': {
            //boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',

            '& $buttonContainer': {
                height: 40
            },

            '& $imageContainer': {
                height: '202px'
            },

            '& $flagVisibility': {
                visibility: 'visible'
            },

            '& $prevNextContainer': {
                visibility: 'visible'
            },

            '& $dotContainer': {
                opacity: 1
            }
        }
    },
    cardSkeleton: {
        [theme.breakpoints.down('sm')]: {
            margin: '1rem 0'
        },

        margin: '0 20px 20px 0',
        border: '1px solid #f2f5f7',
        borderRadius: '2px',
        bgcolor: '#ffffff'
    },
    imageBox: {
        marginRight: '1rem',
        transition: '0.15s all ease',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    flag: {
        visibility: 'hidden',
        alignSelf: 'flex-end',
        mixBlendMode: 'difference',
        color: '#fff',
        cursor: 'pointer',
        //margin: '0 8px 8px 0',
        transition: 'visibility 0.3s ease'
    },
    flagVisibility: {
        visibility: 'hidden',
    },
    dotContainer: {
        alignSelf: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        marginTop: '1rem',
        marginBottom: '1rem',
        textAlign: 'center'
    },
    prevNextContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
        visibility: 'hidden',
        '& svg': {
            mixBlendMode: 'difference'
        }
    },
    buttonContainer: {
        display: 'flex',
        height: 0,
        transition: 'height 0.3s ease',

        '& .MuiButton-root': {
            flex: '1 1',
            borderRadius: 'unset',
            width: '100%'
        },

        '& .MuiButton-text': {
            padding: '6px 16px'
        }
    },
    selectSortBy: {
        //margin: '1rem',
        display: 'flex',

        [theme.breakpoints.down("sm")]: {
            marginRight: '0',
        },

        '& .MuiSelect-selectMenu': {
            color: '#505D68',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '17px'
        },

        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },

        '& .MuiInput-underline:hover': {
            borderBottom: 'none !important'
        }
    },
    dot: {
        fontSize: '16px',
        opacity: '0.5',
        color: theme.palette.primary.main,
        margin: '0 3px',
        '&:hover': {
            opacity: 1,
            transform: 'scale(1.4)'
        }
    },
    dotSelected: {
        fontSize: '16px',
        color: theme.palette.primary.main,
        margin: '0 3px'
    },

    productList: {
        display: 'flex',
        margin: '1rem 0 0 2rem',
        flexWrap: 'wrap',
        minHeight: "615px",

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '1rem 0',
            alignItems: 'center',
        }

    },
    carousel: {
        textAlign: 'center'
    },
    filtersWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '2rem',
        //marginLeft: '2rem',
    },
    filters: {
        display: 'flex',
        // height: '50px',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    filterChipsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    filterChip: {
        display: 'flex',
        alignItems: 'baseline',
        minWidth: '30px',
        maxWidth: '120px',
        marginLeft: '1rem'
    },
    filterText: {
        width: '90%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    customIcon: {
        fontSize: '1rem',
        fill: '#8D8D8D',

        '&:hover': {
            fill: '#8D8D8D'
        }
    },
    bottomDrawer: {
        display: 'none',

        [theme.breakpoints.down('sm')]: {
            display: 'block',

            '& .MuiDrawer-paperAnchorBottom': {
                height: '100%'
            }
        }
    },

    filterItem: {
        // border: '1px solid #E2E2E2',
        // backgroundColor: '#F7F7F7',
        height: '44px',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    activeFilter: {
        backgroundColor: '#ffffff',
        color: '#383838',
        fontFamily: "Open Sans",
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '21px',

        // '& div': {
        //     height: '8px',
        //     width: '8px',
        //     backgroundColor: '#22B11F',
        //     borderRadius: '50%'
        // }
    },
    filterValue: {
        padding: '0.5rem',

        '&:hover': {
            fontWeight: '700'
        }
    },

    clearFilterBtn: {
        color: "#22B11F",
        //marginRight: "15px",
        float: 'right',
        cursor: 'pointer',
        fontSize: "14px"
    },
    filterSearch: {
        display: "flex",
        width: "100%",
        justifyContent: "space-around",

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        }
    },
    noDataMsg: {
        color: "rgb(33, 150, 243)",
        letterSpacing: "0.5px",
        [theme.breakpoints.down('sm')]: {
            padding: '0 15px'
        }
    },
    sortWrapper: {
        display: "flex",
        alignItems: "center",
        marginRight: '15px',

        [theme.breakpoints.down('sm')]: {
            marginRight: '0',
        }
    },
    labelAutoWidth: {
        width: 'auto',
        height: 'auto'
    }
}));

export default GetStyles;
