import { makeStyles } from '@material-ui/core/styles';

const MyRequestStyles = makeStyles((theme) => ({
  product: {
    paddingRight: '2rem',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  addPickupAddress: {
    boxSizing: 'border-box',
    borderRadius: '2px',
    margin: '20px 0',
    backgroundColor: 'aliceblue',
    position: 'relative',
    padding: '1rem'
  },
  field: {
    flex: '1 1',
    margin: '0 20px',

    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
      pointerEvents: 'none',
      cursor: 'none',

      '& .MuiAutocomplete-endAdornment': {
        display: 'none'
      }
    },

    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none'
    }
  },
  accepted: {
    alignSelf: 'baseline',
    padding: '5px 1rem',
    backgroundColor: 'rgba(127,204,40,0.38)',
  },
  dropAddress: {
    boxSizing: 'border-box',
    borderRadius: '2px',
    padding: '15px',
    backgroundColor: 'aliceblue',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '30px',
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    cursor: 'pointer',
    zIndex: 2
  },
  pickupLocation: {
    margin: '1rem 0 0.5rem',
    paddingBottom: '1rem',

    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '1rem',

    '& .MuiExpansionPanel-root': {
      display: 'none',
      margin: '16px 0',
      boxShadow: 'none',

      '& .MuiAccordionSummary-root': {
        background: 'rgba(34,177,31,0.13)',
      },

      '& .MuiAccordionSummary-content': {
        justifyContent: 'center',
        margin: '12px 0 !important',

        '& :before': {
          height: 0,
        },
      },

      '& .MuiAccordionDetails-root': {
        padding: '1rem 0',
      },
    },

    '& .MuiTable-root': {
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            verticalAlign: 'baseline !important',
            padding: '6px 24px 6px 16px !important'
          }
        }
      }
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiExpansionPanel-root': {
        display: 'block',

        '& .MuiGrid-grid-xs-6': {
          display: 'block',
        },
      },

      '& .MuiGrid-container': {
        '&:first-of-type': {
          padding: '1rem !important',
          margin: '0 !important',
        },

        flexDirection: 'column',
        padding: '1rem',

        '& .MuiGrid-item': {
          maxWidth: '100%',
          flexBasis: '100%',
          // padding: '0 !important',
        },

        '& .MuiButton-root': {
          flex: '1 1',
        },
      },
      '& .MuiTableCell-root': {
        verticalAlign: 'baseline !important',
        padding: '6px 24px 6px 16px'
      }
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default MyRequestStyles;
