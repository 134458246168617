import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#22B11F',
      secondary: '#22B11F',
      tabColor: '#1B1B1B;'
    },
    secondary: {
      main: '#231C07',
      secondary: '#F2FFF1'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      primary: '#ffffff'
    }
  },
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application!
    }
  },


  overrides: {
    MuiInput: {
      underline: {
        "&&&&:hover:not($disabled):before": {
          borderBottom: "2px solid yellow !important"
        }
      }
    }
  },


  overrides: {
    MuiTextField: {
      root: {
        margin: 0
      },
      
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
      label: {
        color: '#505D68',
        fontSize: '14px',
        lineHeight: '24px'
      }
    },
    MuiTypography: {
      subtitle1: {
        color: '#383838',
        fontSize: '12px'
      },
      subtitle2: {
        color: '#383838',
        fontSize: '14px'
      },
      body1: {
        color: '#383838',
        fontSize: '16px'
      },
      body2: {
        color: '#383838',
        fontSize: '18px'
      },
      h6: {
        color: '#383838',
        fontSize: '21px'
      },
      h1: {
        color: '#383838',
        fontSize: '21px',
        fontWeight: '700'
      },
      h2: {
        color: '#383838',
        fontSize: '18px',
        fontWeight: '700'
      },
      h3: {
        color: '#383838',
        fontSize: '16px',
        fontWeight: '600'
      },
      h4: {
        color: '#383838',
        fontSize: '14px',
        fontWeight: '700'
      },
      h5: {
        color: '#383838',
        fontSize: '12px',
        fontWeight: '700'
      }
    },
    MuiButton: {
      root: {
        height: '40px',
        minWidth: '117px',
        boxShadow: 'none !important'
      },
      label: {
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center'
      },
      containedPrimary: {
        color: '#ffffff'
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: 'unset',
        padding: '0',

        '&:hover': {
          backgroundColor: '#f7f7f7'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        '&:hover': {
          fill: '#22B11F'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.7686274509803922)'
      },
      arrow: {
        color: 'rgba(0, 0, 0, 0.7686274509803922)'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&$selected': {
          backgroundColor: '#231C07'
        }
      },
      textColorInherit: {
        color: '#ffffff',
        fontSize: '16px',
        opacity: '1'

      },
      wrapper: {
        fontWeight: '400'
      }
    },
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        top: '8px',
        right: '8px'
      },
      badge: {
        height: '15px',
        minWidth: '15px',
        padding: 0,
        borderRadius: '50%'
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'initial'
      }
    },
    MuiDialog: {
      paperScrollPaper: {
        boxSizing: 'border-box',
        width: '828px',
        border: '1px solid #EEEEEE',
        backgroundColor: '#FFFFFF',
        padding: '39px'
      }
    },
    MuiDialogContentText: {
      root: {
        textAlign: 'center'
      }
    },
    // MuiInput: {
    //   underline: {
    //     '&:hover': {
    //       borderBottom: '1px solid rgba(0, 0, 0, 0.42)',

    //       '&:before': {
    //         borderBottom: 'none !important'
    //       }
    //     }
    //   }
    // },
    MuiInputBase: {
      adornedEnd: {
        paddingRight: '10px !important'
      }
    },
    MuiInputLabel: {
      root: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100%'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: '0 !important'
      }
    },
    MuiMenuItem: {
      root: {
        minWidth: '358px',
        '&:hover': {
          background: 'rgba(34,177,31,0.21)'
        }
      }
    },
    MuiExpansionPanel: {
      root: {
        margin: 0,
        
      }
    },
    MuiAccordionSummary: {
      root: {
        padding: '0',
        '& .Mui-expanded': {
          margin: '0',
          minHeight: '0',
        },
        '& .MuiIconButton-edgeEnd':{
          marginRight: '0'
        },
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,

        '& .MuiFormControl-root': {
          //padding: '0 12px'
        },

        '& .PrivateSwitchBase-root-417': {
          padding: '3px'
        },
        '& .PrivateSwitchBase-root-470': {
          padding: '0',
          
        },
        '& .PrivateSwitchBase-root-900': {
          padding: '0',
          
        },
        "& .MuiSvgIcon-root": {
          width: "14px",
        }
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#22B11F'
        }
      }
    },

    PrivateSwitchBase: {
      root: {
        padding: 0,
      },
      
    },

    MuiToggleButton: {
      root: {
        width: '100px',
        height: '40px',

        '&:hover': {
          backgroundColor: '#231C07',
          color: '#fff'
        },

        '&$selected': {
          backgroundColor: '#231C07',
          color: '#fff',

          '&:hover': {
            backgroundColor: '#231C07'
          }
        }
      },
      label: {
        textTransform: 'none'
      }
    },
    MuiAlert: {
      root: {
        fontSize: '14px'
      }
    },
    MuiFilledInput: {
      input: {
        padding: '10px'
      }
    },
   
    MuiAvatar: {
      root: {
        backgroundColor: '#e5e5e5'
      },
      img: {
        objectFit: 'unset'
      }
    }
  },
  
  typography: {
    fontFamily: '"Open Sans", sans-serif'
  }
});

export default theme;
