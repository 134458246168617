import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { FormikTextField } from '../../ui/FormikTextField';
import { FormikSearchTextField } from '../../ui/FormikSearchTextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GiveBooksStyles from './../../../styles/GiveBooks.styles';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import { itemApi } from './../../../api';
import Search from './../../ui/give/Search';
import { useStoreActions, useStoreState } from './../../../hooks';
import BundleContainer from '../../ui/give/BundleContainer';
import DragDropImage from '../../ui/dragDropImage';
import { appSettings } from '../../../constants';
import Rating from '@material-ui/lab/Rating';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Preview from './Preview';
import LoadingIcon from '../../ui/loadingIcon';
import isoLangs from '../../../utils/langList.js';
import * as _ from 'lodash';

const MEDIA_URL = appSettings.MEDIA_URL,
  useStyles = makeStyles((theme) => ({
    show: {
      display: 'block',
    },
    hide: {
      display: 'none',
    },
    helperTextRight: {
      display: 'flex',
      alignSelf: 'flex-end'
    }
  })),
  progressStyle = makeStyles((theme) => ({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
  })),
  schema = yup.object().shape({
    title: yup.string().required('Please enter book title.').nullable(),
    condition: yup.string().required('Please select book condition.').nullable(),
    bookType: yup.string().required('Please select book type.').nullable(),
    year: yup.number().positive().nullable()
      .test({
        name: 'isYearValid',
        test: function (value) {
          if (value) {
            let len = value.toString().length;
            let currentYear = new Date().getFullYear();
            if (len != 4) {
              return this.createError({
                message: `Please enter a valid year.`,
                path: 'year', // Fieldname
              })
            } else if (value > currentYear) {
              return this.createError({
                message: `Please enter year less than or equal to the current year.`,
                path: 'year', // Fieldname
              })
            }
            else {
              return true;
            }
          } else {
            return true;
          }
        }
      }).nullable()
    ,
    bookGenre: yup.string().required('Please select genre type.').nullable(),
    isbn: yup.string().nullable(),
    author: yup.string().required('Please enter author.').nullable(),
    edition: yup.string().nullable(),
    website: yup.string().nullable()
      .matches(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
        'Please enter a valid URL.'
      ).nullable(),
    additionalInfo: yup.string().nullable(),
    lang: yup.string().nullable(),
  });

export const GiveBooks = (props) => {
  const categorySlug = 'Books',
    classesShow = useStyles(),
    progress = progressStyle(),
    user = useStoreState((state) => state.auth.user),
    classes = GiveBooksStyles(),
    [isCategoryLoaded, setCategoryLoaded] = React.useState(0),
    [isUpdate, setIsUpdate] = React.useState(false),
    [showPreview, setShowPreview] = React.useState(false),
    [bookProps, setBooksState] = React.useState({}),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    [imageFiles, setImageFiles] = React.useState([]),
    emptyBookItem = {
      bookTitle: '',
      bookCondition: null,
      bookType: null,
      bookPublishYear: '',
      bookGenre: null,
      bookIsbn: '',
      bookAuthor: '',
      bookEdition: '',
      bookWebsite: '',
      bookInfo: '',
      bookExternalWebsite: '',
      bookDisplayImage: '',
      bookLang: null,
    },
    [bookItem, setBook] = React.useState(emptyBookItem),
    blankBookDetails = {
      title: '',
      isbn13: '',
      authors: {
        author: [
          {
            name: null,
          },
        ],
      },
      authorName: '',
      publication_year: '',
      edition: '',
      bookType: '',
      condition: '',
      edition_information: '',
    };

  const [categoryId, setCategoryId] = React.useState(),
    [categoryName, setCategoryName] = React.useState(),
    [bookDetails, setBookDetails] = React.useState(blankBookDetails),
    [bundleId, setBundleId] = React.useState(''),
    [itemId, setItemId] = React.useState(''),
    [bookList, setBundleList] = React.useState([]),
    [loading, setLoading] = React.useState(false),
    [bundleLoading, setBundleLoading] = React.useState(false),
    [searchTitle, setSearchTitle] = React.useState(''),
    [submitting, setSubmitting] = React.useState(''),
    [isBookNotCorrect, setIsBookNotCorrect] = React.useState(false),
    [buttonName, setButtonName] = React.useState(''),
    [expandIcon, setExpandIcon] = React.useState(false);

  function getRequestBlock(newBookItem) {
    let reqBlk = {
      category: 'book', // categorySlug //categoryName
      category_id: categoryId,
      bundle_id: bundleId,
      i_name: newBookItem.bookTitle,
      i_condition: newBookItem.bookCondition.value,
      i_condition_id: newBookItem.bookCondition.choice_id,
      book_type: newBookItem.bookType.value,
      images: imageFiles,
      display_img: bookDetails.image_url || null,
      is_book_set: false,
      author: newBookItem.bookAuthor,
      // lang: newBookItem.bookLang,
    };

    if (newBookItem.bookIsbn) {
      reqBlk.isbn = newBookItem.bookIsbn;
    }

    if (newBookItem.bookPublishYear) {
      reqBlk.year = newBookItem.bookPublishYear;
    }

    if (newBookItem.bookInfo) {
      reqBlk.additional_info = newBookItem.bookInfo;
    }

    if (newBookItem.bookExternalWebsite) {
      reqBlk.ext_website = newBookItem.bookExternalWebsite;
    }

    if (newBookItem.bookGenre && newBookItem.bookGenre.value) {
      reqBlk.genre = newBookItem.bookGenre.value;
    }

    if (newBookItem.bookEdition && newBookItem.bookEdition) {
      reqBlk.edition = newBookItem.bookEdition;
    }
    if (newBookItem.bookLang && newBookItem.bookLang.name) {
      reqBlk.lang = newBookItem.bookLang.name;
    }

    return reqBlk;
  }

  const [resetKey, setResetKey] = React.useState(1),
    updateDragAndDrop = () => {
      setResetKey(resetKey + 1);
    },
    clearForm = () => {
      setImageFiles([]);
      updateBookDetails('');
      setSearchTitle('');
      setIsUpdate(false);
      setBook({ ...emptyBookItem });
      setIsBookNotCorrect(false);
      updateDragAndDrop();
      window.scrollTo(0, 0);
      return false;
    },
    onSubmit = (newBookItem) => {
      // create bundle
      setSubmitting('submit');
      if (imageFiles && imageFiles.length > 0) {
      } else {
        showAlert({
          message: 'Please upload images',
          open: true,
          severity: 'warning',
        });
        setSubmitting('');

        return false;
      }

      if (bundleId && bookList.length > 0) {
        if (
          newBookItem &&
          newBookItem.bookType &&
          newBookItem.bookCondition &&
          newBookItem.bookPublishYear
        ) {
          let reqBlk = getRequestBlock(newBookItem);

          if (bookDetails) {
            reqBlk.extra_info = bookDetails;
          }

          itemApi.createItem(
            reqBlk,
            function (resp) {
              if (resp) {
                setImageFiles([]);
                itemApi.getItemsByBundle(
                  bundleId,
                  function (resp) {
                    setBundleList(resp.Items);
                    clearForm();
                    setSubmitting('');
                  },
                  function (resp) {
                    setSubmitting('');
                  }
                );
                navigate(`/give/books/${bundleId}/preview`);
              }
              setSubmitting('');
            },
            function () {
              setSubmitting('');
            }
          );
        } else {
          setSubmitting('');
          navigate(`/give/books/${bundleId}/preview`);
        }
      } else {
        itemApi.createBundle(
          function (response) {
            //create item
            setSubmitting('submit');
            setBundleId(response.bundle_id);

            if (response.bundle_id) {
              let reqBlk = getRequestBlock(newBookItem);

              reqBlk.bundle_id = response.bundle_id;

              if (bookDetails) {
                reqBlk.extra_info = bookDetails;
              }

              itemApi.createItem(
                reqBlk,
                function (resp) {
                  setSubmitting('');

                  if (resp) {
                    setImageFiles([]);
                    itemApi.getItemsByBundle(
                      resp.bundle_id,
                      function (resp) {
                        setBundleList(resp.Items);
                        clearForm();
                        setSubmitting('');
                      },
                      function (resp) {
                        setSubmitting('');
                      }
                    );
                    navigate(`/give/books/${response.bundle_id}/preview`);
                  }
                },
                function () {
                  setSubmitting('');
                }
              );
            }
          },
          function (response) {
            setSubmitting('');
          }
        );
      }
    },
    onAddMore = (newBookItem) => {
      if (imageFiles && imageFiles.length > 0) {
      } else {
        showAlert({
          message: 'Please upload images',
          open: true,
          severity: 'warning',
        });

        return false;
      }

      setSubmitting('addmore');

      if (bundleId) {
        let reqBlk = getRequestBlock(newBookItem);

        if (bookDetails) {
          reqBlk.extra_info = bookDetails;
        }

        itemApi.createItem(
          reqBlk,
          function (resp) {
            if (resp) {
              let newImageFiles = [];

              setImageFiles(newImageFiles);

              itemApi.getItemsByBundle(
                resp.bundle_id,
                function (resp) {
                  setBundleList(resp.Items);
                  clearForm();
                  setSubmitting('');
                },
                function (resp) {
                  setSubmitting('');
                }
              );
            }
          },
          function (resp) {
            setSubmitting('');
          }
        );
      } else {
        itemApi.createBundle(
          function (response) {
            setBundleId(response.bundle_id);

            let reqBlk = getRequestBlock(newBookItem);

            reqBlk.bundle_id = response.bundle_id;

            if (bookDetails) {
              reqBlk.extra_info = bookDetails;
            }

            itemApi.createItem(
              reqBlk,
              function (resp) {
                if (resp) {
                  let newImageFiles = [];

                  setImageFiles(newImageFiles);

                  itemApi.getItemsByBundle(
                    resp.bundle_id,
                    function (resp) {
                      if (resp) {
                        setBundleList(resp.Items);
                      }

                      clearForm();
                      setSubmitting('');
                    },
                    function (response) {
                      setSubmitting('');
                    }
                  );
                }
              },
              function (response) {
                setSubmitting('');
              }
            );
          },
          function (resp) {
            setSubmitting('');
          }
        );
      }
    },
    handleEditItem = (bundleId, itemId) => {
      if (bundleId && itemId) {
        setBundleId(bundleId);
        setItemId(itemId);
        setIsUpdate(true);

        itemApi.getItemExtraInfo(itemId, function (response) {
          if (response) {
            updateBookDetails(response);
          }

          itemApi.getItem(
            bundleId,
            itemId,
            function (resp) {
              if (resp) {
                let savedBook = { ...bookItem };

                setSearchTitle(resp.i_name);
                savedBook.bookTitle = resp.i_name;
                //savedBook.bookCondition = resp.i_condition
                //savedBook.bookType = resp.book_type
                setImageFiles(resp.images);
                savedBook.bookPublishYear = resp.year;
                savedBook.bookIsbn = resp.isbn;
                savedBook.bookAuthor = resp.author;
                savedBook.bookExternalWebsite = resp.ext_website;
                savedBook.bookInfo = resp.additional_info;
                //savedBook.bookGenre = resp.genre
                savedBook.bookEdition = resp.edition;
                if (
                  resp.i_condition &&
                  bookProps.bookConditionObj.selection_values
                ) {
                  savedBook.bookCondition = bookProps.bookConditionObj.selection_values.find(
                    (x) => x.value === resp.i_condition
                  );
                }
                if (resp.book_type && bookProps.bookTypeObj.selection_values) {
                  savedBook.bookType = bookProps.bookTypeObj.selection_values.find(
                    (x) => x.value === resp.book_type
                  );
                }
                if (resp.genre && bookProps.genreObj.selection_values) {
                  savedBook.bookGenre = bookProps.genreObj.selection_values.find(
                    (x) => x.value === resp.genre
                  );
                }
                if (resp.lang && isoLangs) {
                  savedBook.bookLang = isoLangs.find(
                    (x) => x.name == resp.lang
                  );
                }
                setBook(savedBook);
                updateDragAndDrop();
              }
            },
            function (response) {
              setSubmitting('');
            }
          );
        });
      }
    },
    updateItem = (newBookItem) => {
      setSubmitting('update');
      if (bundleId && itemId) {
        let reqBlk = getRequestBlock(newBookItem);

        reqBlk.item_id = itemId;

        itemApi.updateItem(
          reqBlk,
          bundleId,
          itemId,
          function (resp) {
            if (resp) {
              itemApi.getItemsByBundle(
                bundleId,
                function (resp) {
                  setIsUpdate(false);
                  setBundleList(resp.Items);
                  clearForm();
                  navigate(`/give/books/${bundleId}/preview`);
                },
                function (response) {
                  setSubmitting('');
                }
              );
            }
          },
          function (response) {
            setSubmitting('');
          }
        );
      }
    },
    notYourBook = (title = '') => {
      setIsBookNotCorrect(true);
      setBookDetails('');
      emptyBookItem.bookTitle = searchTitle?.title ? searchTitle?.title : title;
      setBook(emptyBookItem);
    };

  React.useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    // categoryApi.getCategoryData(
    //   function (response) {
    //     const item = _.find(response, ['slug', categorySlug]);

    //     if (item) {
    //       setCategoryId(item.id);
    //       setCategoryName(item.name);
    //     }
    //   },
    //   function (response) {
    //     setCategoryId(1);
    //   }
    // );

    let bundle_id = props.bundleId;
    if (bundle_id) {
      setBundleLoading(true);
      itemApi.getItemsByBundle(
        bundle_id,
        function (resp) {
          if (resp.bundle && resp.bundle.b_status !== 'new') {
            navigate('/my-gives/1');
          }
          setBundleList(resp.Items);
          setBundleLoading(false);
          setBundleId(bundle_id);
          if (props.itemId) {
            handleEditItem(bundle_id, props.itemId);
          }
        },
        function () {
          setBundleLoading(false);
        }
      );
    }
    itemApi.getFieldsByCategory(categorySlug, function (response) {
      if (response) {
        response.map((item) =>
          item.name === 'book_type'
            ? (bookProps.bookTypeObj = item)
            : item.name === 'book_condition'
              ? (bookProps.bookConditionObj = item)
              : item.name === 'isbn'
                ? (bookProps.bookIsbnObj = item)
                : item.name === 'publish_year'
                  ? (bookProps.publishYearObj = item)
                  : item.name === 'additional_info'
                    ? (bookProps.addInfoObj = item)
                    : item.name === 'book_genre'
                      ? (bookProps.genreObj = item)
                      : item.name === 'website_url'
                        ? (bookProps.bookWebsiteObj = item)
                        : ''
        );
      }

      setBooksState(bookProps);
      setCategoryLoaded(1);
    });
  }, []);

  const handleAddMoreFromPreview = (bundleId) => {
    clearForm();
    setShowPreview(false);

    if (bundleId) {
      setBundleLoading(true);

      itemApi.getItemsByBundle(
        bundleId,
        function (resp) {
          setBundleList(resp.Items);
          setBundleLoading(false);
          setBundleId(bundleId);
        },
        function () {
          setBundleLoading(false);
        }
      );
    }
  },
    updateBookDetails = (object) => {
      let newBookDetails = { ...object, authorName: '' };

      if (newBookDetails.authors) {
        newBookDetails.authorName = newBookDetails.authors
          .map((author) => author)
          .join(', ');
      }

      setBookDetails(newBookDetails);

      if (newBookDetails) {
        let newBookItem = { ...bookItem };

        newBookItem.bookTitle = newBookDetails.title;
        newBookItem.bookAuthor = newBookDetails.authorName;
        newBookItem.bookPublishYear = newBookDetails.publication_year;
        newBookItem.bookIsbn = newBookDetails.isbn13 || newBookDetails.isbn;
        newBookItem.bookEdition = newBookDetails.edition_information;
        newBookItem.bookDisplayImage = newBookDetails.image_url || null;
        newBookItem.bookLang = newBookDetails.lang;
        setBook(newBookItem);
      }
    };
  function clearAll() {
    setBookDetails('');
    setBook(emptyBookItem);
  }
  function dataLoaded() {
    return !showPreview ? (
      <Grid container className={classes.container}>
        <Grid className={classes.titleSection} item xs={12}>
          <Typography
            variant='h1'
            component='div'
            className={classes.mobileHeader}
          >
            {isUpdate != true ? 'Give Books' : 'Edit Book'}
          </Typography>
          {/*<Typography variant='subtitle2' component='div'>Set 2/2</Typography>*/}
        </Grid>
        <Grid className={`${classes.titleSection} ${classes.paddingTopBottom}`} item xs={12}>
          {bundleLoading == true ||
            (bookList.length > 0 && isUpdate != true) ? (
              <BundleContainer
                itemList={bookList}
                bundleLoading={bundleLoading}
                updateItem={setBundleList}
                editItem={handleEditItem}
                setBundleId={setBundleId}
                setItemId={setItemId}
                setBundleList={setBundleList}
                clearForm={clearForm}
              />
            ) : (
              ''
            )}
        </Grid>
        <Grid item xs={6} className={classes.formSection}>
          <Formik
            enableReinitialize
            initialValues={{
              title: bookItem.bookTitle,
              isbn: bookItem.bookIsbn,
              author: bookItem.bookAuthor,
              edition: bookItem.bookEdition,
              year: bookItem.bookPublishYear,
              website: bookItem.bookExternalWebsite,
              condition: bookItem.bookCondition,
              bookType: bookItem.bookType,
              bookGenre: bookItem.bookGenre,
              additionalInfo: bookItem.bookInfo,
              lang: bookItem.bookLang,
            }}
            validationSchema={schema}
            onSubmit={async (
              values,
              { setSubmitting, setFieldError, resetForm }
            ) => {
              try {
                const requestDataBlk = {
                  title: values.title,
                  isbn: values.isbn,
                  author: values.author,
                  edition: values.edition,
                  website: values.website,
                  bookGenre: values.bookGenre,
                  additionalInfo: values.additionalInfo,
                  lang: values.lang,
                };

                let newBookItem = { ...bookItem };
                newBookItem.bookTitle = values.title;
                newBookItem.bookPublishYear = values.year;
                newBookItem.bookIsbn = values.isbn;
                newBookItem.bookAuthor = values.author;
                newBookItem.bookEdition = values.edition;
                newBookItem.bookExternalWebsite = values.website;
                newBookItem.bookInfo = values.additionalInfo;
                newBookItem.bookLang = values.lang;
                setBook(newBookItem);
                if (buttonName === 'submit') {
                  onSubmit(newBookItem);
                } else if (buttonName === 'update') {
                  updateItem(newBookItem, resetForm);
                } else {
                  onAddMore(newBookItem, resetForm);
                }
                setButtonName('');
              } catch ({ error }) {
                showAlert({ message: 'Error', open: true, severity: 'error' });
              }
              setSubmitting(false);
            }}
            render={({ submitForm, setFieldValue, isSubmitting, values }) => (
              <Form style={{ width: '100%' }}>
                <FormGroup>
                  <Box
                    className={
                      isBookNotCorrect === false
                        ? classesShow.show
                        : classesShow.hide
                    }>
                    <Search
                      label='Enter Author or Title'
                      name='book'
                      updateItem={updateBookDetails}
                      categorySlug='Books'
                      fieldId='1'
                      updateLoading={setLoading}
                      searchTitle={searchTitle}
                      setSearchTitle={setSearchTitle}
                      setIsBookNotCorrect={setIsBookNotCorrect}
                      bookItem={bookItem}
                      notYourBook={notYourBook}
                      setBook={setBook}
                      setBookDetails={setBookDetails}
                      bookDetails={bookDetails}
                      clearAll={clearAll}
                    />
                  </Box>
                  <Box
                    mt={2}
                    className={
                      isBookNotCorrect === true
                        ? classesShow.show
                        : classesShow.hide
                    }
                  >
                    <Field
                      fullWidth
                      type='text'
                      label='Book Title'
                      name='title'
                      value={
                        bookItem?.bookTitle?.length > 40
                          ? bookItem.bookTitle.substring(0, 40) + '...'
                          : bookItem.bookTitle
                      }
                      component={FormikTextField}
                      onChange={(e) => {
                        setFieldValue('title', e.target.value);
                        setBook({ ...bookItem, bookTitle: e.target.value });
                      }}
                    />
                    {/* className={isBookNotCorrect===false ? classesShow.show : classesShow.hide} */}
                  </Box>
                  {bookDetails && !isBookNotCorrect && bookDetails.title && (
                    <ExpansionPanel onChange={changeExpandIcon}>
                      <AccordionSummary
                        className={classes.mobileExpand}
                        expandIcon={
                          expandIcon ? (
                            <svg
                              class='MuiSvgIcon-root'
                              focusable='false'
                              viewBox='0 0 24 24'
                              aria-hidden='true'
                            >
                              <path d='M19 13H5v-2h14v2z'></path>
                            </svg>
                          ) : (
                              <img
                                height='14'
                                width='24'
                                src='/assets/images/icon_plus.svg'
                              />
                            )
                        }
                      >
                        <Typography variant='' component='div'>
                          View search result
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box width='100%'>{getShowSearchResult()}</Box>
                      </AccordionDetails>
                    </ExpansionPanel>
                  )}
                  <Box mt={2}>
                    {/* <Typography variant='subtitle2' component='div'>
                      Upload Images
                    </Typography> */}
                  </Box>
                  <DragDropImage
                    style={{ minHeight: '160px', overflow: 'unset' }}
                    ListImages={imageFiles}
                    key={resetKey}
                    imageFiles={imageFiles}
                    setImageFiles={setImageFiles}
                  />
                  <Box
                    mt={2}
                    className={
                      isBookNotCorrect === true
                        ? classesShow.show
                        : classesShow.hide
                    }
                  >
                    <Field
                      fullWidth
                      type='text'
                      label='Author'
                      name='author'
                      component={FormikTextField}
                      value={bookItem.bookAuthor}
                      onChange={(e, value) => {
                        setFieldValue('author', value);
                        setBook({
                          ...bookItem,
                          bookAuthor: value,
                          bookTitle: values.title,
                          bookCondition: values.condition,
                          bookPublishYear: values.year,
                          bookExternalWebsite: values.website,
                          bookInfo: values.additionalInfo,
                          bookLang: values.lang,
                        });
                      }}
                    />
                    {/* <TextField label='Author' fullWidth name='author'  disabled={bookDetails && bookDetails.authors.author} value={bookDetails && bookDetails.authors.author[0].name ?  bookDetails.authors.author[0].name :''} /> */}
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      options={bookProps.bookConditionObj.selection_values}
                      getOptionLabel={(option) => option.value}
                      getOptionDisabled={(option) =>
                        option.value === 'Needs Repair'
                      }
                      className={classes.mobileAutoComplete}
                      onChange={(e, value) => {
                        setFieldValue('condition', value);
                        setBook({
                          ...bookItem,
                          bookCondition: value,
                          bookTitle: values.title,
                          bookAuthor: values.author,
                          bookPublishYear: values.year,
                          bookExternalWebsite: values.website,
                          bookInfo: values.additionalInfo,
                          bookLang: values.lang,
                        });
                      }}
                      value={bookItem.bookCondition}
                      renderInput={(params) => (
                        <Field
                          fullWidth
                          {...params}
                          label={
                            bookProps.bookConditionObj.is_required
                              ? bookProps.bookConditionObj.label
                              : bookProps.bookConditionObj.label + ' (Optional)'
                          }
                          name='condition'
                          component={FormikSearchTextField}
                          className={classes.mobileFormLabel}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      options={bookProps.bookTypeObj.selection_values}
                      getOptionLabel={(option) => option.value}
                      onChange={(e, value) => {
                        setFieldValue('bookType', value);
                        setBook({
                          ...bookItem,
                          bookType: value,
                          bookTitle: values.title,
                          bookAuthor: values.author,
                          bookPublishYear: values.year,
                          bookExternalWebsite: values.website,
                          bookInfo: values.additionalInfo,
                          bookLang: values.lang,
                        });
                      }}
                      value={bookItem.bookType}
                      renderInput={(params) => (
                        <Field
                          fullWidth
                          {...params}
                          label={
                            bookProps.bookTypeObj.is_required
                              ? bookProps.bookTypeObj.label
                              : bookProps.bookTypeObj.label + ' (Optional)'
                          }
                          name='bookType'
                          component={FormikSearchTextField}
                          className={classes.mobileFormLabel}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      options={
                        bookProps.genreObj &&
                        bookProps.genreObj.selection_values
                      }
                      getOptionLabel={(option) => option.value}
                      onChange={(e, value) => {
                        setFieldValue('bookGenre', value);
                        setBook({
                          ...bookItem,
                          bookGenre: value,
                          bookTitle: values.title,
                          bookAuthor: values.author,
                          bookPublishYear: values.year,
                          bookExternalWebsite: values.website,
                          bookInfo: values.additionalInfo,
                          bookLang: values.lang,
                        });
                      }}
                      value={bookItem.bookGenre}
                      renderInput={(params) => (
                        <Field
                          fullWidth
                          {...params}
                          label={
                            // bookProps.genreObj.is_required ?
                            bookProps.genreObj.label
                            // : bookProps.genreObj.label + ' (Optional)'
                          }
                          name='bookGenre'
                          component={FormikSearchTextField}
                          className={classes.mobileFormLabel}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      fullWidth
                      type='number'
                      value={bookItem.bookPublishYear}
                      label={
                        bookProps.publishYearObj.is_required
                          ? bookProps.publishYearObj.label
                          : bookProps.publishYearObj.label + ' (Optional)'
                      }
                      name='year'
                      component={FormikTextField}
                      className={classes.mobileFormLabel}
                    />
                    {bookItem.bookPublishYear && (
                      <Typography
                        variant='subtitle2'
                        component='div'
                        style={{ color: '#EB933C' }}
                      >
                        Please update year if different for your item.
                      </Typography>
                    )}
                  </Box>
                  <Box
                    mt={2}
                    className={
                      isBookNotCorrect === true
                        ? classesShow.show
                        : classesShow.hide
                    }
                  >
                    <Field
                      fullWidth
                      type='text'
                      label='ISBN (Optional)'
                      name='isbn'
                      value={bookItem.bookIsbn}
                      component={FormikTextField}
                      className={classes.mobileFormLabel}
                    />
                  </Box>
                  <Box
                    mt={2}
                    className={
                      isBookNotCorrect === true
                        ? classesShow.show
                        : classesShow.hide
                    }
                  >
                    <Field
                      fullWidth
                      type='text'
                      label='Edition (Optional)'
                      name='edition'
                      component={FormikTextField}
                      onChange={(e) => {
                        setBook({ ...bookItem, bookEdition: e.target.value });
                      }}
                      className={classes.mobileFormLabel}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      options={isoLangs}
                      getOptionLabel={(option) => option.name}
                      className={classes.mobileAutoComplete}
                      onChange={(e, value) => {
                        setFieldValue('lang', value);
                        setBook({
                          ...bookItem,
                          bookLang: value,
                          bookGenre: values.bookGenre,
                          bookTitle: values.title,
                          bookAuthor: values.author,
                          bookPublishYear: values.year,
                          bookExternalWebsite: values.website,
                          bookInfo: values.additionalInfo,
                        });
                      }}
                      value={bookItem?.bookLang}
                      defaultValue={bookItem?.bookLang}
                      renderInput={(params) => (
                        <Field
                          fullWidth
                          {...params}
                          label={
                            'Language (Optional)'
                          }
                          name='lang'
                          component={FormikSearchTextField}
                          className={classes.mobileFormLabel}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      fullWidth
                      type='text'
                      value={bookItem.bookExternalWebsite}
                      label={
                        bookProps.bookWebsiteObj.is_required
                          ? bookProps.bookWebsiteObj.label
                          : bookProps.bookWebsiteObj.label + ' (Optional)'
                      }
                      name='website'
                      component={FormikTextField}
                      className={classes.mobileFormLabel}
                      onChange={(e) => {
                        setBook({
                          ...bookItem,
                          bookExternalWebsite: e.target.value,
                          bookGenre: values.bookGenre,
                          bookTitle: values.title,
                          bookAuthor: values.author,
                          bookPublishYear: values.year,
                          bookLang: values.lang,
                          bookInfo: values.additionalInfo,
                        });
                      }}
                    />
                  </Box>

                  {/* <Box mt={2} >
                <FormControl className={classes.formControl}>
                    <InputLabel ref={inputLabel} htmlFor='outlined-age-native-simple'>{bookProps.bookTypeObj.label}</InputLabel>
                    <Select native value={state.age} onChange={handleChange('age')} inputProps={{name: 'age', id: 'outlined-age-native-simple', }} >
                        <option value='' />
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                    </Select>
                </FormControl>
              </Box> */}
                  {/*<Box mt={1}>
                <FormGroup row>
                  <FormControlLabel control={ <AntSwitch /> } label='Yes, Complete' />
                  <FormControlLabel control={ <AntSwitch /> } label='Yes, Partial' />
                  <FormControlLabel control={ <AntSwitch /> } label='No' />
                </FormGroup>
              </Box>
              <Box mt={2}>
                <TextField
                  label='Number of books in the boxset (Optional)'
                  type='number'
                  InputLabelProps={{ shrink: true,}}
                  variant='outlined'
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <Typography variant='subtitle2' component='div'>Select volumes or books included: (7)</Typography>
              </Box>
              <Box className={classes.root}>
                {[...Array(10)].map((item, index) =>
                  <div className={classes.volumeNumber} key={index}>{index + 1}</div>
                )
                }
              </Box>*/}
                  <Box mt={2}>
                    <Field
                      multiline
                      label={
                        bookProps.addInfoObj.is_required
                          ? bookProps.addInfoObj.label
                          : bookProps.addInfoObj.label + ' (Optional)'
                      }
                      placeholder='Please provide any other relevant information'
                      rows='3'
                      name='additionalInfo'
                      fullWidth
                      inputProps={{ maxLength: 200 }}
                      value={bookItem.bookInfo}
                      component={FormikTextField}
                      className={classes.mobileFormLabel}
                      onChange={(e, value) => {
                        setFieldValue('additionalInfo', value);
                        setBook({
                          ...bookItem,
                          bookInfo: value,
                          bookTitle: values.title,
                          bookAuthor: values.author,
                          bookPublishYear: values.year,
                          bookExternalWebsite: values.website,
                          bookGenre: values.bookGenre,
                          bookLang: values.lang,
                        });
                      }}
                      FormHelperTextProps={{ classes: { root: classesShow.helperTextRight } }}
                      helperText={`${values?.additionalInfo ? values.additionalInfo.length : 0}/200`}
                    />
                  </Box>
                  {!isUpdate ? (
                    <>
                      <Box
                        mt={4}
                        display='flex'
                        className={classes.buttonContainer}
                        flexDirection='row-reverse'
                      >
                        <div className={progress.wrapper}>
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={submitting}
                            onClick={(e) => {
                              setButtonName('submit');
                              submitForm();
                            }}
                          >
                            {' '}
                            Next{' '}
                          </Button>
                          {submitting === 'submit' && (
                            <CircularProgress
                              size={24}
                              className={progress.buttonProgress}
                            />
                          )}
                        </div>
                        <div className={progress.wrapper}>
                          <Button
                            variant='outlined'
                            color='primary'
                            disabled={
                              submitting ||
                              (bookList.length >= 4 ? true : false)
                            }
                            onClick={(e) => {
                              setIsBookNotCorrect(false);
                              setButtonName('onAddMore');
                              submitForm();
                            }}
                          >
                            Add More
                          </Button>
                          {submitting === 'addmore' && (
                            <CircularProgress
                              size={24}
                              className={progress.buttonProgress}
                            />
                          )}
                        </div>
                        <div className={progress.wrapper}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={(e) => {
                              clearForm();
                            }}
                          >
                            {bookList.length > 0 ? 'Cancel' : 'Clear'}
                          </Button>
                        </div>
                      </Box>
                      <Box className={classes.fixedButtonSection}>
                        <div className={progress.wrapper}>
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={submitting}
                            onClick={(e) => {
                              setButtonName('submit');
                              submitForm();
                            }}
                          >
                            Next
                          </Button>
                          {submitting === 'submit' && (
                            <CircularProgress
                              size={24}
                              className={progress.buttonProgress}
                            />
                          )}
                        </div>
                        <div className={progress.wrapper}>
                          <Button
                            variant='outlined'
                            color='primary'
                            disabled={
                              submitting ||
                              (bookList.length >= 4 ? true : false)
                            }
                            onClick={(e) => {
                              setIsBookNotCorrect(false);
                              setButtonName('onAddMore');
                              submitForm();
                            }}
                          >
                            Add More
                          </Button>
                          {submitting === 'addmore' && (
                            <CircularProgress
                              size={24}
                              className={progress.buttonProgress}
                            />
                          )}
                        </div>
                        <div className={progress.wrapper}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={(e) => {
                              clearForm();
                            }}
                          >
                            {bookList.length > 0 ? 'Cancel' : 'Clear'}
                          </Button>
                        </div>
                      </Box>
                    </>
                  ) : (
                      <>
                        <Box
                          mt={4}
                          display='flex'
                          className={classes.buttonContainer}
                          flexDirection='row-reverse'
                        >
                          <div className={progress.wrapper}>
                            <Button
                              variant='contained'
                              color='primary'
                              disabled={submitting}
                              onClick={(e) => {
                                setButtonName('update');
                                submitForm();
                              }}
                            >
                              Update
                          </Button>
                            {submitting === 'update' && (
                              <CircularProgress
                                size={24}
                                className={progress.buttonProgress}
                              />
                            )}
                          </div>
                          <div className={progress.wrapper}>
                            <Button
                              variant='outlined'
                              color='primary'
                              disabled={submitting}
                              onClick={(e) => {
                                window.history.back();
                              }}
                            >
                              Cancel
                          </Button>
                          </div>
                        </Box>
                        <Box className={classes.fixedButtonSection}>
                          <div className={progress.wrapper}>
                            <Button
                              variant='contained'
                              color='primary'
                              disabled={submitting === 'update' ? true : false}
                              onClick={(e) => {
                                setButtonName('update');
                                submitForm();
                              }}
                            >
                              Update
                          </Button>
                            {submitting === 'update' && (
                              <CircularProgress
                                size={24}
                                className={progress.buttonProgress}
                              />
                            )}
                          </div>
                          <div className={progress.wrapper}>
                            <Button
                              variant='outlined'
                              color='primary'
                              disabled={submitting}
                              onClick={(e) => {
                                if (bookList.length > 0) {
                                  navigate(`/give/books/${bundleId}/preview`);
                                } else {
                                  navigate(`/my-gives/0`);
                                }
                              }}
                            >
                              Cancel
                          </Button>
                          </div>
                        </Box>
                      </>
                    )}
                </FormGroup>
              </Form>
            )}
          />
        </Grid>

        {bookDetails && !isBookNotCorrect && bookDetails.title ? (
          <Grid item xs={6} className={classes.formSection2}>
            <Box
              className={classes.rectangle}
              style={{ backgroundColor: 'rgba(127,204,40,0.03)' }}
            >
              {getShowSearchResult()}
            </Box>
            {/* <Box
              className={classes.rectangle}
              style={{ maxHeight: '375px', height: '80px' }}
            >
              <RectangleAds></RectangleAds>
            </Box> */}
          </Grid>
        ) : (
            <Grid item xs={6} className={classes.formSection2}>
              <Box
                className={classes.rectangle}
                style={{ backgroundColor: 'rgba(34,177,31,0.07)' }}>
                <div
                  height='388px'
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'rgb(251, 253, 248)',
                    fontWeight: '600',
                    padding: '35px 10px',
                  }}>
                  <img
                    alt='waiting'
                    width='70%'
                    src='/assets/images/waiting.svg' />
                </div>
              </Box>
              <Alert
                severity='info'
                style={{ marginTop: '20px', marginBottom: '20px' }}>
                {!loading && (isBookNotCorrect ? 'Please enter book details.' : 'Search results will appear here.')}
                {loading && 'Waiting for Search results'}
              </Alert>
            </Grid>

          )}
      </Grid>
    ) : (
        <Preview
          bundleid={bundleId}
          onCancel={handleAddMoreFromPreview}
          // updatePreferece={updateUserPreferences}
          editItem={handleEditItem}
          // preference={preference}
          // setPrefrences={setPrefrences}
          submitting={submitting}
          setPage={props.setPage}
        />
      );
  }

  const getShowSearchResult = () => {
    return (
      <>
        {bookDetails && !isBookNotCorrect && bookDetails.title && (
          <Box width='100%' m={4}>
            <Box display='flex'>
              <img
                alt='dummy text'
                height='120px'
                width='66px'
                src={
                  bookDetails && bookDetails.image_url
                    ? bookDetails.image_url
                    : '/assets/images/waiting.svg'
                }
              />
              <Box marginLeft='2rem'>
                <Typography variant='h6' component='div'>
                  {bookDetails && bookDetails.title}
                </Typography>
                <Typography variant='subtitle1' component='div'>
                  {bookDetails.authors.length > 0 ? 'by' : ''}{' '}
                  {bookDetails && bookDetails.authors.length > 1
                    ? bookDetails.authors[0] +
                    ' +' +
                    (bookDetails.authors.length - 1)
                    : bookDetails.authors[0]}
                </Typography>
                <Rating
                  name='disabled'
                  style={{ fontSize: '10px' }}
                  value={+bookDetails.average_rating}
                  disabled
                />
                {/*<Typography variant='h4' component='div' style={{ fontWeight: '600', paddingTop: '10px'}}>Powered by</Typography>*/}
                <img
                  alt='dummy text'
                  style={{
                    fontSize: '1rem',
                    display: 'block',
                    marginTop: '1rem',
                  }}
                  height='34px'
                  width='63px'
                  src={MEDIA_URL + '/static' + bookDetails.powered_by_logo}
                />
              </Box>
            </Box>

            <Typography
              variant='h4'
              component='div'
              style={{ marginTop: '1rem' }}
            >
              Product Details
            </Typography>
            {bookDetails.num_pages && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  Paperback:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.num_pages}
                </Typography>
              </div>
            )}
            {bookDetails.publisher && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  Publisher:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.publisher}
                </Typography>
              </div>
            )}
            {bookDetails.publication_year && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  Year:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.publication_year}
                </Typography>
              </div>
            )}
            {bookDetails.language_code && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  Language:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.language_code}
                </Typography>
              </div>
            )}
            {bookDetails.isbn && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  ISBN-10:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.isbn}
                </Typography>
              </div>
            )}
            {bookDetails.isbn13 && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  ISBN-13:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.isbn13}
                </Typography>
              </div>
            )}
            {bookDetails.asin && (
              <div className={classes.itemDetails}>
                <Typography variant='h5' component='div'>
                  ASIN:
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  style={{ marginLeft: '5px' }}
                >
                  {bookDetails && bookDetails.asin}
                </Typography>
              </div>
            )}
            <div className={classes.itemDetails}>
              <Typography variant='h5' component='div'>
                Author(s):
              </Typography>
              <Typography
                variant='subtitle1'
                component='div'
                style={{ marginLeft: '5px' }}
              >
                {bookDetails &&
                  bookDetails.authors &&
                  bookDetails.authors.map(function (author, index) {
                    return <span>{(index ? ', ' : '') + author}</span>;
                  })}
              </Typography>
            </div>
            <br />
            <br />
            <span style={{ marginLeft: '25%' }}>
              Not your book?{' '}
              <a
                onClick={(e) => notYourBook()}
                style={{
                  color: '#578ed5',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Enter manually
              </a>
            </span>
          </Box>
        )}
      </>
    );
  };

  function dataNotLoaded() {
    return (
      <section id='intro1' style={{ minHeight: '530px' }}>
        <div className='intro-container1'>
          <LoadingIcon loadingMsg='Loading ...' />
        </div>
      </section>
    );
  }

  function changeExpandIcon() {
    setExpandIcon(!expandIcon);
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {
            /*user && user.u_status && user.u_status == 2 ? bookProps.bookIsbnObj ?  dataLoaded() :  dataNotLoaded()
            :
            user && user.u_status && user.u_status == 1 ? VerifyEmailDialog('Verify Email', 'Please verify your email before proceeding further.')
            :
            VerifyEmailDialog('Login', 'Please login using your email before proceeding further.')*/
            bookProps.bookIsbnObj ? dataLoaded() : dataNotLoaded()
          }
        </Grid>
      </Grid>
    </>
  );
};
