import React from 'react'
import BundleItem from './BundleItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import GiveBooksStyles from './../../../styles/GiveBooks.styles';
import 'react-multi-carousel/lib/styles.css';


function BundleContainer(props) {
  const classes = GiveBooksStyles();

    return (
        <Grid item xs={12} className={classes.bundleContainer}>
            {
                props.bundleLoading ?
                    <div style={{textAlign: 'center'}}>
                        <img width="50" src="/assets/images/loading-cgk.gif"/>
                        <div style={{fontSize:"10px"}}>Loading items...</div>
                    </div> : ''
            }
              {props.itemList && props.itemList.map(function (item, index) {
                return (
                  <Box ml={2} style={{margin: '3px'}}> 
                    <BundleItem slug={props.category.slug} item={item} key={index} itemUpdate={props.setItemList} itemEdit={props.editItem} setBundleId={props.setBundleId} setItemId={props.setItemId}  setBundleList={props.setBundleList} clearForm={props.clearForm} />
                  </Box>
                )
              })}
        </Grid>
    )
}

export default BundleContainer
