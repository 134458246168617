import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { appSettings } from "../../constants";
import GiveBooksStyles from "./../../styles/GiveBooks.styles";
import Axios from "axios";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useStoreActions } from "../../store";
import imageCompression from 'browser-image-compression';
import CircularProgress from "@material-ui/core/CircularProgress";

const Preview = ({ meta }) => {
  const { name, percent, status } = meta;
  return (
    <></>
    // <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
    //   {name}, {Math.round(percent)}%, {status}
    // </span>
  );
};

const imageOverlayStyle = makeStyles((theme) => ({
  button: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    cursor: "pointer",
  },
  wrapper: {
    margin: "0 1rem 0 0",
    position: "relative",
    display: "inline-flex",
  },
  imageBox: {
    margin: "0 1rem 0 0",
  },
}));

const PreviewImage = (props) => {
  const classes = imageOverlayStyle(),
    [mouseOver, setMouseOver] = React.useState(false),
    imgUrl = props.file.startsWith("http")
      ? props.file
      : appSettings.MEDIA_URL + "/" + props.file;

  return (
    <div className={classes.wrapper}>
      <img
        src={imgUrl}
        width="58px"
        height="47px"
        style={{ opacity: mouseOver ? ".3" : "1", margin: "auto" }}
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
      ></img>
      <DeleteForeverIcon
        visibility={mouseOver ? "visible" : "hidden"}
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
        className={classes.button}
        onClick={() => {
          props.onDelete(props.file);
        }}
      />
    </div>
  );
};

const progressBarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  wrapper: {
    width: "100%",
    position: "absolute",
    display: "block",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(255,255,255,0.5)",
    zIndex: "2",
  },
  fileProgress: {
    color: "#7FCC28",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyle = makeStyles((theme) => ({
  uploadButton: {
    cursor: "pointer",
    padding: "5px 15px",
    borderRadius: "4px",
    border: "1px solid #21B01E",
    color: "#22B11F",
    fontSize: ".875rem",

    [theme.breakpoints.down("sm")]: {
      width: '165px',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#22B11F',
      fontFamily: "Open Sans",
      fontSize: '14px',
      letterSpacing: '0',
      lineHeight: '18px',
      zIndex: '999'
    },
  },
  uploadIcon: {
    display: 'none',
    [theme.breakpoints.down("sm")]: {
      marginRight: '12px',
      display: 'inline-block'
    }
  },
  dragDropLabel: {
    [theme.breakpoints.down("sm")]: {
      color: '#555555',
      fontFamily: "Open Sans",
      fontSize: '14px',
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center',
      zIndex: '999'
    }
  }
}));

const DragDropImage = (props) => {
  const classes = GiveBooksStyles(),
    imageClasses = imageOverlayStyle(),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    useStyles = useStyle(),
    authToken = "Token " + localStorage.getItem("token"),
    [previewFiles, setPreviewFiles] = React.useState(
      [].concat(props.item.upload_images || [])
    ),
    [isUploading, setUploadingFlag] = React.useState(false),
    progressBarclasses = progressBarStyles();

  React.useEffect(() => {
    updateEmptyFiles(props.item.upload_images || []);
  }, []);

  const handleUploadErrorCall = (response, resStatus) => { };

  const InputArea = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const classes = GiveBooksStyles();
    const text = files.length > 0 ? "Add more files" : "Choose files";
    return (
      <>
        <Typography variant="subtitle2" component="div" className={useStyles.dragDropLabel}>
          Drag and drop
        </Typography>
        <Typography variant="subtitle2" component="div" className={useStyles.dragDropLabel}>
          Or
        </Typography>
        <label
          className={useStyles.uploadButton}
          color="primary"
        >
          <img height="16" width="18" src="/assets/images/icon_plus.svg" className={useStyles.uploadIcon} />
          Upload images
          <input
            style={{ display: "none" }}
            type="file"
            accept={accept}
            multiple={false}
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </label>
      </>
    );
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1600,
        useWebWorker: true
      },
        files = e.target.files || e.dataTransfer.files;
      if (files.length === 0 || files.length > 4) {
        e.preventDefault();
        return;
      } else {
        const name = files[0]?.name,
          lastModified = files[0]?.lastModifiedDate;

        if (name) {
          imageCompression(files[0], options)
            .then(function (compressedFile) {
              compressedFile.name = name;
              compressedFile.lastModifiedDate = lastModified;
              resolve([compressedFile]); // write your own logic
            }).catch(function (error) {
              showAlert({
                message: "Unable to upload a file. This file type is not supported!",
                open: true,
                severity: "error",
              });
            });
        }
      }
    });
  };

  const getUploadParams = ({ file, meta }) => {
    setUploadingFlag(true);
    if (props.item.upload_images?.length <= 4) {
      var formData = new FormData();
      var imagefile = document.querySelector("#file");
      formData.append("docfile", file);
      let uploadURL = appSettings.BaseURL + "api/core/media/upload/";
      Axios({
        method: "post",
        url: uploadURL,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(function (response, resStatus) {
        const newList = [...props.item.upload_images, response.data.file_path];
        updateEmptyFiles(newList);
        props.setItem({ ...props.item, upload_images: newList });
        props.formikFields.setValues({ ...props.formikFields.values, upload_images: newList });
        setUploadingFlag(false);
        return response.data;
      });
    } else {
      showAlert({
        message: "Maximun files have been uploaded... ",
        open: true,
        severity: "warning",
      });
      setUploadingFlag(false);
    }
  };

  const imageDeleted = (image) => {
    const newList = [...props.item.upload_images],
      index = newList.indexOf(image);

    if (index !== -1) {
      newList.splice(index, 1);
    }

    updateEmptyFiles(newList);
    props.setItem({ ...props.item, upload_images: newList });
    props.formikFields.setValues({ ...props.formikFields.values, upload_images: newList });
  };

  const updateEmptyFiles = (uploadedFilesList) => {
    let previewFilesList = [];
    for (let i = 0; i < 4 - uploadedFilesList.length; i++) {
      previewFilesList.push("favicon.ico");
    }
    setPreviewFiles(previewFilesList);
  };

  return (
    <>
      <div className={progressBarclasses.root} style={{ marginTop: '16px' }}>
        <Dropzone
          accept="image/*"
          multiple={true}
          maxFiles={50}
          previewImage={false}
          getUploadParams={getUploadParams}
          PreviewComponent={Preview}
          // onSubmit={handleSubmit}
          InputComponent={InputArea}
          getFilesFromEvent={getFilesFromEvent}
          styles={{ overflow: "unset" }}
        />
        {isUploading && (
          <div className={progressBarclasses.wrapper}>
            <CircularProgress
              size={30}
              className={progressBarclasses.fileProgress}
            />
          </div>
        )}
      </div>
      <Box
        mt={2}
        className={classes.root}
        style={{ display: "flex", alignItems: "center" }}
      >
        {props.item.upload_images?.length > 0 ? (
          <>
            {props.item.upload_images?.map((file, index) => (
              <PreviewImage key={index} file={file} onDelete={imageDeleted} />
            ))}
            {previewFiles.map((file, index) => (
              <div className={imageClasses.imageBox} key={index}>
                <img
                  alt=""
                  src="/assets/images/uploadImage.svg"
                  width="58px"
                  height="47px"
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {previewFiles.map((file, index) => (
              <div className={imageClasses.imageBox} key={index}>
                <img
                  alt=""
                  src="/assets/images/uploadImage.svg"
                  width="58px"
                  height="47px"
                />
              </div>
            ))}
          </>
        )}
      </Box>
    </>
  );
};

<DragDropImage />;
export default DragDropImage;
//<DragDropImage />