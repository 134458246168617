import * as React from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import { FieldProps, getIn } from 'formik';
import TextField from '@material-ui/core/TextField';

// export type TextFieldProps = FieldProps &
//   Omit<MuiTextFieldProps, 'error' | 'name' | 'onChange' | 'value'> & {
//     // Fix for the type for variant which is using union
//     // https://stackoverflow.com/questions/55664421
//     variant: 'standard' | 'filled' | 'outlined' | undefined;
//   };

export const fieldToTextField = ({
  field,
  form,
  disabled,
  ...props
}) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    ...field,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled != undefined ? disabled : isSubmitting,
  };
};

export const FormikSearchTextField = ({
  children,
  ...props
}) => (
  <TextField {...fieldToTextField(props)} variant='outlined' size='small' >{children}</TextField>
);

FormikSearchTextField.displayName = 'FormikMaterialUITextField';
