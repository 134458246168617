import React from 'react';
import { useStoreState } from './../../hooks';
import { ItemImagesContainer } from '../../components/ui/ItemImagesContainer';
import { ItemDetails } from '../../components/ui/ItemDetails';
import { GoodReadDetails } from '../../components/ui/GoodReadDetails';
import { appSettings } from './../../constants/appSettings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, AccordionSummary, AccordionDetails, Box, Grid, Typography } from '@material-ui/core';
import { itemApi } from '../../api';
import { utilHelper } from './../../helpers/utilHelper';
import { RequestListItem } from './RequestListItem';
import { LargeRectangleAds } from '../ui/ads/LargeRectangleAds';
import { navigate } from '@reach/router';
import MyRequestStyles from './../../styles/MyRequest.styles';

const BASE_URL = appSettings.BaseURL;

const ItemRequests = (props) => {
    const classes = MyRequestStyles(),
        [displayIndex, setDisplayIndex] = React.useState(0),
        handleClick = () => {
            if (canDecline) {
                handleNoResponseClick(acceptedResponse.bundle_id, acceptedResponse.request_id);
            } else {
                const title = utilHelper.slugify(item.itemTitle[0]);

                navigate(`/my-gives/${title}/${item.bundle_id}/?isDeliveryPage=true`, {
                    state: { item: { ...item } },
                });
            }
        },
        handleNoResponseClick = (bundle_id, request_id) => {
            setRequestLoading(true);
            itemApi.updateBundleRejectRequest(
                bundle_id,
                request_id,
                function (response) {
                    setRequestLoading(false);
                    if (response) {
                        navigate(`/my-gives/${title}/${item.bundle_id}`, {
                            state: { item: { ...item } },
                        })
                    } else {
                        showAlert({ message: 'Error', open: true, severity: 'error' });
                        return false;
                    }
                },
                function () {
                    setRequestLoading(false);
                }
            );
        },
        [shareUrl, setShareUrl] = React.useState(''),
        isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
        [item, setItem] = React.useState(null),
        [itemDetails, setItemDetails] = React.useState([]),
        [RequestList, setRequestList] = React.useState([]),
        [extraInfo, setExtraInfo] = React.useState([]),
        [request_id, setRequest_Id] = React.useState(),
        [user_id, setUser_Id] = React.useState(),
        [reqName, setReqName] = React.useState(''),
        [acceptedResponse, setAcceptedResponse] = React.useState(''),
        [loadingExtraInfo, setLoadingExtraInfo] = React.useState(false),
        [requestLoading, setRequestLoading] = React.useState(false),
        [canDecline, setCanDecline] = React.useState(false);

    const updateItemObject = (item) => {
        let nemItem = {
            ...item,
            itemTitle: [],
            itemImage: [],
            category: [],
            condition: [],
            id: [],
        };
        if (item && item.title) {
            let keys = Object.keys(item.title);
            keys.map(function (itemTitle, i) {
                nemItem.id[i] = itemTitle;
                nemItem.itemTitle[i] = item.title[itemTitle];
                let img = item.images[itemTitle].startsWith('http')
                    ? item.images[itemTitle]
                    : appSettings.MEDIA_URL + item.images[itemTitle];
                nemItem.itemImage[i] = img;
                nemItem.itemImage[i] = nemItem.itemImage[i].replace('\\', '/');
                nemItem.itemImage[i] = nemItem.itemImage[i].replace('\\', '/');
                let cat_condition = item.cat_condition[itemTitle].split('_');
                nemItem.category[i] = cat_condition[0];
                nemItem.condition[i] = cat_condition[1];
            });
        }
        return nemItem;
    }

    const getItemsDetails = (item) => {
        itemApi.getItemsDetails(item.bundle_id, function (response) {
            if (response) {
                getExtraInfo(response.Items[0].item_id);

                let newrItemDeatilsesponse = [];
                for (let i = 0; i < response.Items.length; i++) {
                    newrItemDeatilsesponse[response.Items[i].item_id] = response.Items[i];
                }
                setItemDetails(newrItemDeatilsesponse);
                setShareUrl(
                    BASE_URL +
                    'get/details/books/' +
                    utilHelper.slugify(response.Items[0].i_name) +
                    '/' +
                    item.bundle_id
                );
                if (response.can_decline) {
                    setCanDecline(true);
                } else {
                    setCanDecline(false);
                }
            }

            setRequestLoading(true);

            itemApi.getRequestsByBundleId(
                item.bundle_id,
                function (response) {
                    setRequestLoading(false);
                    if (response) {
                        response.map((item, i) => {
                            if (item.b_status == 'accepted') {
                                setAcceptedResponse(item);
                            }
                        });
                        setRequestList(response);
                    }
                },
                function () {
                    setRequestLoading(false);
                }
            );
        });
    }

    React.useEffect(() => {
        if (isLoggedIn) {
            itemApi.getMyGiveItemByBundle(
                props['item-id'],
                function (response) {
                    if (response.bundle && response.bundle.title) {
                        let nemItem = updateItemObject(response.bundle);
                        setItem(nemItem);
                        getItemsDetails(nemItem);
                    }
                });
        } else {
            navigate('/login', { state: { redirectUrl: window.location.pathname } });
        }
    }, []);

    let newRequest;

    const getExtraInfo = (itemId) => {
        setLoadingExtraInfo(true);
        itemApi.getItemExtraInfo(itemId, function (response) {
            if (response) {
                setExtraInfo(response);
                setLoadingExtraInfo(false);
            }
        });
    };

    const getProductDetails = () => {
        return (
            <Grid item xs={6} className={classes.product}>
                <ItemImagesContainer
                    itemDetails={itemDetails}
                    titles={item.itemTitle}
                    item={item}
                    shareUrl={shareUrl}
                    getExtraInfo={getExtraInfo}
                    displayIndex={displayIndex}
                    setDisplayIndex={setDisplayIndex}
                ></ItemImagesContainer>
                <Box mt={4}>
                    <ItemDetails
                        itemDetails={itemDetails}
                        displayIndex={displayIndex}
                        item={item}
                    ></ItemDetails>
                </Box>
                {extraInfo.authorName && (
                    <Box mt={4}>
                        <GoodReadDetails
                            extraInfo={extraInfo}
                            loading={loadingExtraInfo}
                        ></GoodReadDetails>
                    </Box>
                )}
            </Grid>
        );
    };

    return (
        <Box
            display='flex'
            flexDirection='column'
            bgcolor='#fff'
            className={classes.container}>

            <Grid container style={{ margin: '2rem 0' }}>
                {item && getProductDetails()}
                <ExpansionPanel>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5' component='div'>
                            View details
            </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item && getProductDetails()}</AccordionDetails>
                </ExpansionPanel>

                <Grid item xs={6}>
                    {RequestList && (
                        <Box width='100%'>
                            {RequestList.map((item, i) => {
                                newRequest = { ...item };
                                return (
                                    <RequestListItem
                                        item={newRequest}
                                        key={i}
                                        acceptedResponse={acceptedResponse}
                                        canDecline={canDecline}
                                        handleClick={handleClick}
                                        setUser_Id={setUser_Id}
                                        setRequest_Id={setRequest_Id}
                                        setReqName={setReqName}
                                        request_id={newRequest.request_id}
                                        user_id={newRequest.user_id}
                                        display_name={newRequest.display_name}
                                    />
                                );
                            })}
                        </Box>
                    )}
                    <Box width='100%' display='none'>
                        <LargeRectangleAds></LargeRectangleAds>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
export { ItemRequests };
