import { makeStyles, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const GetStylesLocal = makeStyles((theme) => ({
    product: {
        paddingRight: "2rem",

        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },

    wrapper: {
        display: "flex",
        flexDirection: "row",

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    marpad: {
        padding: "1rem",
        marginRight: "15px",

        [theme.breakpoints.down("sm")]: {
            marginRight: "0",
            marginBottom: "15px",
        },
    },

    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: "1rem",
        margin: "2rem 0",

        "& .MuiExpansionPanel-root": {
            display: "none",
            margin: "16px 0",
            boxShadow: "none",

            "& .MuiAccordionSummary-root": {
                background: "rgba(34,177,31,0.13)",
            },

            "& .MuiAccordionSummary-content": {
                justifyContent: "center",
                margin: "12px 0 !important",

                "& :before": {
                    height: 0,
                },
            },

            "& .MuiAccordionDetails-root": {
                padding: "1rem 0",
            },

        },

        [theme.breakpoints.down("sm")]: {
            "& .MuiExpansionPanel-root": {
                display: "block",

                "& .MuiGrid-grid-xs-6": {
                    display: "block",
                },
            },

            "& .MuiGrid-container": {
                flexDirection: "column",

                "& .MuiGrid-item": {
                    maxWidth: "100%",
                    flexBasis: "100%",
                    //padding: "0 !important",
                },

                "& .MuiButton-root": {
                    flex: "1 1",
                },
            },
        },
    },
    helperTextRight: {
        display: "flex",
        alignSelf: "flex-end"
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        color: 'rgb(13, 60, 97)',
        backgroundColor: 'aliceblue',
        maxWidth: '220px',
        border: '1px solid #dadde9',
        fontSize: '12px',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        lineHeight: '1.43',
        borderRadius: '4px',
        margin: 'auto',
    },
}))(Tooltip);

export { GetStylesLocal, HtmlTooltip };