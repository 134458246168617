import React, { useState } from 'react';
import { LoginForm } from './forms/LoginForm';
import { Grid, Box, Typography } from '@material-ui/core';
import { CommonLeftBlock } from './commonLeftBlock';
import { navigate } from '@reach/router';
import { useStoreState, useStoreActions } from './../../../hooks';
import tabContainerStyles from './../../../styles/tabContainer.styles'
import { securityApi } from './../../../api/securityApi';
import { appSettings } from "./../../../constants/appSettings";
import LoadingIcon from "./../../ui/loadingIcon";
import { makeStyles } from "@material-ui/core/styles";
import { userApi } from "../../../api";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '-1.5rem',
    },

    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'columns'
        }
    },

    rightblock: {
        alignItems: 'center',
        backgroundColor: 'rgba(73,73,73,7%)',
        justify: 'center'
    },

    verifyEmailSection: {
        boxSizing: 'border-box',
        padding: '15px !important',
        letterSpacing: '1px',
        position: 'absolute',
        top: '2rem',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'aliceblue',
        color: "rgb(13, 60, 97)",
        '& .MuiTypography-subtitle2': {
            fontWeight: '600 !important'
        },

        [theme.breakpoints.down('sm')]: {
            position: 'static',
            transform: 'none'
        }
    }
}));

const Login = (props) => {
    const classesTabContainer = tabContainerStyles(),
        classes = useStyles(),
        redirect_uri = appSettings.SOCIAL_REDIRECT_URL,
        redirectUrl = props?.location?.state?.redirectUrl,
        login = useStoreActions(actions => actions.auth.login),
        showAlert = useStoreActions((actions) => actions.alert.showAlert),
        isLoggedIn = useStoreState(state => state.auth.isLoggedIn),
        logout = useStoreActions(actions => actions.auth.logout),
        [isLoading, setLoading] = useState(true),
        token = useStoreState((state) => state.auth.token),
        updateVerifyEmailSent = useStoreActions(actions => actions.auth.updateVerifyEmailSent),
        verifyEmailData = props?.location?.state?.verifyEmailData,
        isPasswordReset = props?.location?.state?.isPasswordReset,
        invalidSession = props?.location?.state?.invalidSession,
        user = useStoreState((state) => state.auth.user),
        getErrorMessage = (errorCode) => {
            switch (errorCode) {
                case 200:
                    return "Thank you. Your email is confirmed. Please sign-in.";
                case 406:
                    return "You have already confirmed your account. Please sign-in.";
                case 'passwordReset':
                    return "Your password has been reset successfully. Please sign-in.";
                case 'invalid-session':
                    return "You have been redirected to login because of inactivity.";
                case 412:
                case 424:
                default:
                    return "Your activation link has expired.";
            }
        },
        resendVerificationEmail = () => {
            const reqBlk = {
                "key": token
            };

            userApi.resendVerificationEmail(reqBlk, handleSuccessResendVerificationEmail, handleErrorResendVerificationEmail)
        },
        handleSuccessResendVerificationEmail = () => {
            verifyEmailData.isError = false;
            verifyEmailData.errorCode = undefined;
        },
        handleErrorResendVerificationEmail = (resp) => {
            verifyEmailData.isError = true;
            verifyEmailData.errorCode = resp.response.status;
        }

    React.useEffect(() => {
        const queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            code = urlParams.get('code'),
            scope = urlParams.get('scope');

        if (code) {
            if (scope) {
                socialLogin("google-oauth2", code);
            } else {
                socialLogin("facebook", code);
            }
        } else {
            setLoading(false);
        }

        if (isLoggedIn) {
            navigate('/');
        }

        return () => { updateVerifyEmailSent({ isVerifyEmailSent: false }); };
    }, [])

    const socialLogin = (provider, code) => {
        let requestDataBlk = {
            "provider": provider,
            "code": code,
            "redirect_uri": redirect_uri,
        }
        securityApi.socialLogin(requestDataBlk, function (response) {
            if (response.key && response.user) {
                let user = { ...response.user }
                login({ token: response.key, user: user });
                setLoading(false);
                navigate('/dashboard', { replace: true });
            } else {
                showAlert({
                    message: "A user is already registered with this e-mail address.",
                    open: true,
                    severity: "error",
                });
                logout();
                setLoading(false);
                navigate('/login', { replace: true });
            }
        }, function (response) {
            showAlert({
                message: "Login Failed. Please try again",
                open: true,
                severity: "error",
            });
            logout();
            setLoading(false);
            navigate('/login', { replace: true });
        });
    }

    return (
        <div className={classes.root}>
            <div className={classesTabContainer.tabContainer}></div>
            <Grid container>
                <CommonLeftBlock></CommonLeftBlock>
                <Grid item sm={12} xs={12} md={6} className={classes.rightblock}>
                    {isLoading ?
                        <Grid item ><div style={{ maxWidth: '' }}><LoadingIcon loadingMsg="Loading ..." /></div></Grid>
                        :
                        <Grid item style={{ position: 'relative' }}>
                            {verifyEmailData && !verifyEmailData.isError && <Box className={classes.verifyEmailSection} bgcolor="rgb(232, 244, 253) !important">
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='subtitle2' style={{ marginLeft: '0.5rem', color: 'rgb(13, 60, 97)' }} component='div'>
                                        {getErrorMessage(200)}</Typography>
                                </Box>
                            </Box>}

                            {invalidSession && <Box className={classes.verifyEmailSection} bgcolor="rgb(232, 244, 253) !important">
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='subtitle2' style={{ marginLeft: '0.5rem', color: 'rgb(13, 60, 97)' }} component='div'>
                                        {getErrorMessage('invalid-session')}</Typography>
                                </Box>
                            </Box>}

                            {isPasswordReset && <Box className={classes.verifyEmailSection} bgcolor="rgb(232, 244, 253) !important">
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='subtitle2' style={{ marginLeft: '0.5rem', color: 'rgb(13, 60, 97)' }} component='div'>
                                        {getErrorMessage('passwordReset')}</Typography>
                                </Box>
                            </Box>}

                            {verifyEmailData && verifyEmailData.isError && <Box className={classes.verifyEmailSection}>
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='subtitle2' style={{ marginLeft: '0.5rem', color: '#000' }} component='div'>
                                        {getErrorMessage(verifyEmailData.errorCode)}{" "}
                                        {verifyEmailData.errorCode === 412 &&
                                            <span>
                                                A new link has been sent to your {" "}
                                                <span
                                                    className={classes.resendLink}
                                                    onClick={resendVerificationEmail}>
                                                    {user ? user.email : 'email'}.
                                                </span>
                                            </span>
                                        }
                                        {verifyEmailData.errorCode === 424 && <a href="mailto:contactus@kindeeds.com">Contact us.</a>}
                                    </Typography>
                                </Box>
                            </Box>}
                            <LoginForm redirectUrl={redirectUrl || null} />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </div>
    )

};
export { Login };
